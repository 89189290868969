import { callApi } from "..";

export const apiMerchantCreateMenu = (data) =>
  callApi(
    `mutation(
        $name: String!, 
        $description: String, 
        $is_active: Boolean!, 
        $mon_active: Boolean!, 
        $tue_active: Boolean!, 
        $wed_active: Boolean!,
        $thu_active: Boolean!,
        $fri_active: Boolean!,
        $sat_active: Boolean!,
        $sun_active: Boolean!,
        $start_time: String!,
        $end_time: String!
        ) {
        merchantCreateMenu(
            input: {
              name: $name
              description: $description
              is_active: $is_active
              mon_active: $mon_active
              tue_active: $tue_active
              wed_active: $wed_active
              thu_active: $thu_active
              fri_active: $fri_active
              sat_active: $sat_active
              sun_active: $sun_active
              start_time: $start_time
              end_time: $end_time
            }
          ) {
            entity_id
            name
            description
            is_active
            mon_active
            tue_active
            wed_active
            thu_active
            fri_active
            sat_active
            sun_active
            start_time
            end_time
          }
  }`,
    data
  );

export const apiMerchantUpdateMenu = (data) =>
  callApi(
    `mutation(
        $id: Int!,
        $name: String!, 
        $description: String, 
        $is_active: Boolean!, 
        $mon_active: Boolean!, 
        $tue_active: Boolean!, 
        $wed_active: Boolean!,
        $thu_active: Boolean!,
        $fri_active: Boolean!,
        $sat_active: Boolean!,
        $sun_active: Boolean!,
        $start_time: String!,
        $end_time: String!
        ) {
        merchantUpdateMenu(
            id: $id
            input: {
              name: $name
              description: $description
              is_active: $is_active
              mon_active: $mon_active
              tue_active: $tue_active
              wed_active: $wed_active
              thu_active: $thu_active
              fri_active: $fri_active
              sat_active: $sat_active
              sun_active: $sun_active
              start_time: $start_time
              end_time: $end_time
            }
          ) {
            entity_id
            name
            description
            is_active
            mon_active
            tue_active
            wed_active
            thu_active
            fri_active
            sat_active
            sun_active
            start_time
            end_time
          }
  }`,
    data
  );

export const apiGetMenuDetail = (data) =>
  callApi(
    `query($id: Int!) {
        merchantMenu(id: $id) {
          entity_id
          name
          description
          is_active
          mon_active
          tue_active
          wed_active
          thu_active
          fri_active
          sat_active
          sun_active
          start_time
          end_time
        }
      }`,
    data
  );

export const apiMerchantDeleteMenu = (data) =>
  callApi(
    `mutation($id: Int!) {
        merchentDeleteMenu(id: $id)
      }
    `,
    data
  );

export const apiGetMenu = (data) =>
  callApi(
    `query(
      $search: String, 
      $currentPage: Int, 
      $pageSize: Int,
      $field: String!, 
      $position: SortEnum!) {
        merchantMenus(
          search: { name: $search }
          currentPage: $currentPage
          pageSize: $pageSize
          sort: { field: $field, position: $position }
        ) {
          total_count
          page_info {
            page_size
            current_page
            total_pages
          }
          items {
            entity_id
            name
            description
            is_active
            mon_active
            tue_active
            wed_active
            thu_active
            fri_active
            sat_active
            sun_active
            start_time
            end_time
          }
        }
      }
      `,
    data
  );
