import { useEffect, useState } from "react";
import Header from "components/Header";
import MenuBar from "components/MenuBar";
import SearchInput from "components/SearchInput";
import CustomButton from "components/CustomButton";
import icon_plus from "assets/icon/icon_plus.svg";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import { apiGetProduct } from "apis/Product";
import { debounce } from "lodash";
import { AlertContext } from "handlers/alertContext";
import { useContext } from "react";

import "./index.scss";
import { ColumnsItem } from "./columns";

const Index = () => {
  const history = useHistory();
  const [productList, setMenuList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const { openModal } = useContext(AlertContext);

  const windowHeight = window.innerHeight;

  const handleTableChange = (pagination, _, sorter) => {
    const { field, order } = sorter;
    const { current: page, pageSize } = pagination;
    setPageInfo((prev) => ({
      ...prev,
      page,
    }));
    const params = {
      page,
      pageSize,
    };
    if (field && order) {
      params.position = order == "descend" ? "DESC" : "ASC";
      params.field = field;
    }
    getProducts(params);
  };

  const getProducts = ({ search, page, pageSize, field, position }) => {
    setLoading(true);
    apiGetProduct({
      search: search || "",
      currentPage: page || 1,
      pageSize: pageSize || 10,
      field: field || "entity_id",
      position: position || "DESC",
    }).then((res) => {
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      setMenuList(res?.data?.merchantProducts?.items ?? []);
      setPageInfo({
        totalPage: res?.data?.merchantProducts?.total_count,
        pageSize:
          res?.data?.merchantProducts?.items?.length > 10
            ? res?.data?.merchantProducts?.items?.length
            : 10,
      });
      setLoading(false);
    });
  };

  const searchData = (textSearch) => {
    getProducts({ search: textSearch, page: 1, textSearch });
    setPageInfo((prev) => ({
      ...prev,
      page: 1,
      textSearch,
    }));
  };

  const onChange = (e) => {
    searchData(e.target.value);
  };

  const debouncedOnChange = debounce(onChange, 500);

  useEffect(() => {
    getProducts({
      search: "",
      page: 1,
      pageSize: 10,
      // field: "name",
      // position: "ASC",
    });
  }, []);

  return (
    <>
      <Header />
      <div className="container-box body_history">
        <MenuBar title="Menu Management / Items" />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <SearchInput
            onChange={debouncedOnChange}
            placeholder="Enter item name..."
          />
          <CustomButton
            style={{ marginLeft: 16 }}
            leftIcon={icon_plus}
            title="New Item"
            onClick={() => history.push("new_item")}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Table
            rowKey="order_number"
            columns={ColumnsItem}
            dataSource={[...productList]}
            loading={isLoading}
            className="table-menu"
            rowClassName="custom-row"
            scroll={{ y: windowHeight - 300 }}
            key="key_Items"
            onRow={(record) => ({
              onClick: () => {
                history.push(`edit_item/${record?.id}`);
              },
            })}
            pagination={{
              current: pageInfo?.page,
              pageSize: pageInfo?.pageSize,
              total: pageInfo?.totalPage,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
