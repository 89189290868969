import { Input, InputProps } from "antd";
import styled from "styled-components";
export const DarkInputDefault = styled(Input)`
  &::placeholder {
    color: #808080;
    opacity: 1;
    font-size: 14px;
  }
  background: "var(--field-background)";
  border: 0px;
  height: 56px;
  color: "var(--field-text-icon)";
`;
export const DarkInput = (props) => {
  return <DarkInputDefault {...props}>{props.children}</DarkInputDefault>;
};
