import { formatPrice } from "utils/CommomFunction";
import { CURRENTCY } from "utils/format";
export const columnRevenue = [
  {
    title: "Phương thức thanh toán",
    dataIndex: "paymentMethod",
    width: 150,
    align: "center",
    render: (paymentMethod, item) => {
      let style = {
        textAlign: "left",
        fontWeight: "400",
      };
      if (paymentMethod === "Tổng cộng") {
        style.fontWeight = "bold";
        style.textTransform = "uppercase";
      }
      return <div style={style}>{paymentMethod}</div>;
    },
  },
  {
    title: "Số đơn Hủy",
    dataIndex: "ordersCancelled",
    align: "center",
    render: (ordersCancelled) => (
      <div
        className="order-price"
        style={{ color: "#FF2129", fontWeight: "bold" }}
      >
        {ordersCancelled}
      </div>
    ),
  },
  {
    title: "Số đơn Boom",
    dataIndex: "ordersBoom",
    align: "center",
    render: (ordersBoom) => (
      <div
        className="order-count"
        style={{ color: "#FFA437", fontWeight: "bold" }}
      >
        {ordersBoom}
      </div>
    ),
  },
  {
    title: "Số đơn Hoàn thành",
    dataIndex: "ordersCompleted",
    align: "center",
    width: 125,
    render: (ordersCompleted) => (
      <div
        className="order-price"
        style={{ color: "#A1C89C", fontWeight: "bold" }}
      >
        {ordersCompleted}
      </div>
    ),
  },
  {
    title: "Tổng số đơn",
    dataIndex: "total",
    align: "center",
    render: (total) => (
      <div className="order-price" style={{ fontWeight: "bold" }}>
        {total}
      </div>
    ),
  },
  {
    title: "Doanh thu",
    dataIndex: "revenue",
    align: "center",
    render: (total) => (
      <div className="order-price" style={{ fontWeight: "bold" }}>
        {formatPrice(total)} {CURRENTCY}
      </div>
    ),
  },
];


export 
const columnsMenu = [
  {
    title: "No.",
    dataIndex: "entity_id",
    key: "entity_id",
    render: (entity_id) => {
      return (
        <div style={{ color: "var(--text-primary)", fontSize: 16 }}>
          {entity_id}
        </div>
      );
    },
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (name) => {
      return (
        <div
          style={{
            color: "var(--primary-6)",
            fontWeight: "600",
            fontSize: 17,
          }}
        >
          {name}
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "is_active",
    key: "is_active",
    sorter: true,
    render: (is_active) => {
      if (is_active == true) {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                background: "var(--primary-6)",
                fontWeight: "600",
                fontSize: 17,
                height: 44,
                width: 100,
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 300,
              }}
            >
              Active
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                background: "var(--field-background)",
                fontWeight: "400",
                fontSize: 17,
                height: 44,
                width: 100,
                color: "var(--text-secondary)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 300,
              }}
            >
              InActive
            </div>
          </div>
        );
      }
    },
  },
];