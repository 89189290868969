import { Modal } from "antd";
import React from "react";

const ModalShipping = ({
    renderTitleModal,
    isShowModalShipping,
    closeModalShipping,
    footer,
    renderModalData,
    detailOrder,
    dataOrderModal,
    openModalDelivered
}) => {
    return (
        <Modal
            key="shipping"
            centered={true}
            destroyOnClose={true}
            className="modal-shipping"
            title={renderTitleModal()}
            open={isShowModalShipping}
            width={900}
            onCancel={closeModalShipping}
            footer={footer}
        >
            {renderModalData(detailOrder, dataOrderModal, openModalDelivered)}
        </Modal>
    );
};

export default ModalShipping;
