import { formatMoney } from "utils/CommomFunction";

export const ColumnsItem = [
  {
    title: "No.",
    dataIndex: "id",
    sorter: true,
    key: "id",
    render: (id) => {
      return <div style={{ color: "#1D2433", fontSize: 16 }}>{id}</div>;
    },
    width: "5%",
  },
  {
    title: "",
    dataIndex: "thumbnail",
    key: "thumbnail",
    align: "center",
    width: 100,
    render: (thumbnail) => {
      return (
        <img
          src={
            thumbnail?.url ||
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          }
          width={80}
          style={{ borderRadius: 8 }}
        />
      );
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
    key: "name",
    render: (name) => {
      return (
        <div style={{ color: "#389E0D", fontWeight: "600", fontSize: 17 }}>
          {name}
        </div>
      );
    },
    width: "30%",
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: true,
    key: "price",
    render: (price) => {
      return (
        <div style={{ color: "#1D2433", fontSize: 16 }}>
          {formatMoney(price?.regularPrice?.amount?.value)}
        </div>
      );
    },
    width: "10%",
  },
  {
    title: "Category",
    dataIndex: "categories",
    key: "categories",
    render: (categories) => {
      return (
        <div style={{ color: "#1D2433", fontSize: 16 }}>
          {categories?.[0]?.name}
        </div>
      );
    },
    width: "15%",
  },
  {
    title: "Stock",
    dataIndex: "stock_status",
    key: "stock_status",
    render: (stock_status) => {
      return (
        <div
          style={{
            color: stock_status?.includes?.("OUT")
              ? "var(--error-2-default)"
              : "var(--text-primary)",
            fontSize: 16,
          }}
        >
          {stock_status?.includes?.("OUT") ? "Out of stock" : "In stock"}
        </div>
      );
    },
    width: "10%",
  },
  {
    title: "Quantity",
    dataIndex: "qty",
    key: "qty",
    render: (qty) => {
      return (
        <div
          style={{
            color: "var(--text-primary)",
            fontSize: 16,
          }}
        >
          {qty}
        </div>
      );
    },
    width: "10%",
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    key: "status",
    width: "15%",

    render: (status) => {
      if (status == 1) {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                background: "#389E0D",
                fontWeight: "600",
                fontSize: 17,
                height: 44,
                width: 100,
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 300,
              }}
            >
              {"Active"}
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                background: "#EAECF0",
                fontWeight: "400",
                fontSize: 17,
                height: 44,
                width: 100,
                color: "#4A505C",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 300,
              }}
            >
              {"InActive"}
            </div>
          </div>
        );
      }
    },
  },
];
