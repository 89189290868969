import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { Table, Modal, Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { formatPrice } from "utils/CommomFunction";
import moment from "moment";
import "./index.scss";
import arrowBack from "../../assets/arrow.svg";
import { useHistory } from "react-router-dom";
import {
  apiApproveRefund,
  apiGetListOrderRefund,
  apiNotRefund,
} from "apis/Order";
import { apiGetShipperList } from "apis/Shipper";
import { renderFooterRefunds } from "./render";
import { renderModalData } from "../Home/render";
import { ModalCooking } from "../../components/Modal";
import { CustomInput } from "components";
import { useTranslation } from "react-i18next";

const { confirm, info } = Modal;

const RefundPage = () => {
  const [orderList, setOrderList] = useState([]);
  const [orderListFilter, setOrderListFilter] = useState([]);
  const [orderDetail, setOrderDetail] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [dataOrderModal, setDataOrderModal] = useState();
  const [shipperList, setShipperList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState();
  const history = useHistory();
  const [reload, set_reload] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [isProcessingNotRefundDelivery, setProcessingNotRefundDelivery] =
    useState(false);
  const [selectedShipper, setSelectedShipper] = useState(null);

  const [isAlert, setAlert] = useState(false);
  const { t } = useTranslation();
  const columns = [
    {
      title: t("orderNumber"),
      dataIndex: "order_number",
      width: 100,
      render: (order_number) => <div>#{order_number}</div>,
    },
    {
      title: t("createAt"),
      dataIndex: "created_at",
      align: "center",
      width: 150,
      render: (time) => (
        <div className="order-price">{moment(time).format("YYYY-MM-DD ")}</div>
      ),
    },
    {
      title: t("shippingMethod"),
      dataIndex: "shipping_method",
      align: "center",
      width: 200,
      render: (method) => (
        <div className="order-price" style={{ textAlign: "center" }}>
          {method
            ?.toString?.()
            .replace?.("Nhận tại cửa hàng", "Hẹn lấy tại cửa hàng")}
        </div>
      ),
    },
    {
      title: "SHIPPER",
      dataIndex: "shipper_info",
      align: "left",
      width: 150,
      render: (shipper_info) => {
        let shipper = shipperList?.find(
          (shipper) => shipper.id === shipper_info[0]?.id
        );
        if (shipper) {
          return <div>{`${shipper?.last_name} ${shipper?.first_name}`}</div>;
        }
      },
    },
    {
      title: t("grandTotal"),
      dataIndex: "grand_total",
      align: "center",
      width: 150,
      render: (grand_total) => (
        <div className="order-price">{formatPrice(grand_total)}đ</div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (status) => (
        <Tag
          color="#F0810D"
          style={{ padding: "5px 25px", borderRadius: "5px" }}
        >
          ĐÃ HUỶ
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      align: "right",
      width: 50,
      render: (status, item) => (
        <div
          key={1}
          className="view-more"
          onClick={() => {
            fetchOrderDetail(item.id);
            setDataOrderModal(item);
            setIsShowModal(true);
          }}
        >
          Xem
        </div>
      ),
    },
  ];

  const fetchOrderDetail = (id) => {
    // apiGetDetailOrder({ id }).then((res) => {
    //   if (!res.errors && res.data) {
    //     setOrderDetail(res.data.orderDetail);
    //   }
    // });
  };

  const renderTitleModal = () => {
    return (
      <div key={orderDetail?.id}>
        <div className="title-left">
          <h3>
            Đơn hàng #{orderDetail?.order_number}{" "}
            <Tag
              color="#4D4D4D"
              style={{ opacity: "0.5", paddingTop: "0.5px" }}
            >
              HỦY
            </Tag>
          </h3>
          {orderDetail?.external_order_id && (
            <p style={{ fontWeight: "400", fontSize: 13 }}>
              {`Mã đơn ${
                orderDetail?.order_type == "CALL CENTER" ||
                orderDetail?.order_type == "STORE"
                  ? "Hoa Sao"
                  : "zalo"
              }: `}
              #
              {dataOrderModal?.order_type == "CALL CENTER" ||
              dataOrderModal?.order_type == "STORE"
                ? dataOrderModal?.external_order_id
                : dataOrderModal?.external_order_id
                    ?.toString()
                    ?.slice(
                      dataOrderModal?.external_order_id?.toString().length - 9
                    )}{" "}
            </p>
          )}
          <h5>
            <p>{`Nhận đơn lúc ${moment(orderDetail?.created_at).format(
              "hh:mm A"
            )}`}</p>
            {orderDetail?.assign_from?.name &&
              orderDetail?.status === "pending" && (
                <>
                  <span></span>
                  <b>{`Được chuyển từ ${orderDetail?.assign_from?.name}`}</b>
                </>
              )}
          </h5>
        </div>
      </div>
    );
  };

  const handleOkRefund = (id) => {
    apiApproveRefund({ id }).then((res) => {
      if (!res.errors && res.data) {
        if (res.data?.approveRefund?.result) {
          return info({
            icon: <></>,
            title: <span style={{ fontWeight: "bold" }}>THÀNH CÔNG</span>,
            content: <span>Hoàn tiền thành công</span>,
            onOk() {
              setIsShowModal(false);
              setOrderDetail();
              setIsLoading(true);
              apiGetListOrderRefund().then((res) => {
                setIsLoading(false);
                if (!res.errors && res.data) {
                  setOrderList(res.data.merchantOrderHistory?.orders);
                  setOrderListFilter(res.data.merchantOrderHistory?.orders);
                }
              });
              set_reload(!reload);
              Modal.destroyAll();
            },
            onCancel() {},
          });
        } else {
          return info({
            icon: <></>,
            title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
            content: <span>Hoàn tiền thất bại</span>,
            onOk() {
              setIsShowModal(false);
              setOrderDetail();

              Modal.destroyAll();
            },
            onCancel() {},
          });
        }
      }
    });
  };

  const handleSubmitRefund = (orderDetail) => {
    return confirm({
      className: "refund-confirm-modal",
      icon: <></>,
      content: (
        <div className="confirm-refund">
          Xác nhận hoàn tiền cho đơn hàng <br />{" "}
          <span>#{orderDetail?.order_number}</span>
        </div>
      ),
      footer: <></>,
      onOk: () => handleOkRefund(orderDetail.id),
      okText: "XÁC NHẬN",
      cancelText: (
        <>
          <CloseCircleOutlined
            style={{ color: "#e31837", fontSize: "16px" }}
            size={4}
          />{" "}
          HỦY
        </>
      ),
    });
  };

  const handleSearch = (e) => {
    let newOrderListFilter = [...orderList];
    newOrderListFilter = newOrderListFilter.filter((order) =>
      order.order_number.includes(e.target.value)
    );
    setOrderId(e.target.value);
    setOrderListFilter(newOrderListFilter);
  };

  const confirmNotRefund = async () => {
    if (orderDetail?.shipping_method !== "Nhận tại cửa hàng") {
      setProcessingNotRefundDelivery(true);
    } else {
      try {
        apiNotRefund({ id: orderDetail?.id, shipperId: 0 }).then((res) => {
          if (!res.errors && res.data) {
            return info({
              icon: <></>,
              title: <span style={{ fontWeight: "bold" }}>THÀNH CÔNG</span>,
              content: (
                <span>Đơn hàng đã chuyển sang trạng thái sẵn sàng giao</span>
              ),
              onOk() {
                closeModal();
                getListOrderRefund();
              },
              onCancel() {},
            });
          }
        });
      } catch (error) {}
    }
  };

  const closeModal = () => {
    setIsShowModal(false);
    setProcessingNotRefundDelivery(false);
  };

  const getListOrderRefund = () => {
    apiGetListOrderRefund().then((res) => {
      setIsLoading(false);
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrderHistory?.orders);
        setOrderListFilter(res.data.merchantOrderHistory?.orders);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getListOrderRefund();
    apiGetShipperList().then((res) => {
      if (!res.errors && res.data) {
        setShipperList(res.data.getShipperList.list);
      }
    });
  }, []);

  const handleSelectShipper = (item) => {
    if (item?.orders?.length >= 2) {
      return info({
        icon: <></>,
        content: <span>Shipper chỉ có thể giao 2 đơn hàng cùng lúc</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setSelectedShipper(item?.id);
  };

  const handleSubmitReadyToShip = () => {
    if (!selectedShipper) {
      return info({
        icon: <></>,
        content: <span>Vui lòng chọn nhân viên giao hàng</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setLoadingButton(true);
    apiNotRefund({ id: dataOrderModal.id, shipperId: selectedShipper }).then(
      (res) => {
        setLoadingButton(false);
        if (res?.data && res?.data?.noRefund && res?.data?.noRefund?.result) {
          return info({
            icon: <></>,
            title: <span style={{ fontWeight: "bold" }}>THÀNH CÔNG</span>,
            content: (
              <span>Đơn hàng đã chuyển sang trạng thái sẵn sàng giao</span>
            ),
            onOk() {
              closeModal();
              getListOrderRefund();
            },
            onCancel() {},
          });
        } else if (
          res?.errors &&
          res?.errors?.length &&
          res?.errors?.length > 0
        ) {
          alert(res?.errors[0]?.message);
        }
        // getOrderList();
      }
    );
  };

  const timeToEnd = (dataOrderModal) => {
    if (dataOrderModal?.pickup_date && dataOrderModal?.pickup_time) {
      const time = `${moment(dataOrderModal?.pickup_date, [
        "YYYY-MM-DD  HH:mm:ss",
      ]).format("YYYY-MM-DD ")} ${dataOrderModal?.pickup_time}`;
      return time;
    }

    return moment(dataOrderModal?.created_at)
      .add(Number(dataOrderModal?.delivery_time), "m")
      .format("hh:mm A");
  };

  return (
    <div className="refunds-page">
      <Header reload={reload} />
      <div className="container-box">
        <div className="header-bottom">
          <h3 className="header-bottom-left">HOÀN TIỀN</h3>
          <div
            className="header-bottom-right"
            style={{ justifyContent: "flex-end" }}
          >
            <div className="search-bar">
              <CustomInput
                placeholder={t("search")}
                value={orderId}
                onChange={handleSearch}
              />
            </div>

            <button className="back-btn" onClick={() => history.push("/home")}>
              {" "}
              <img
                alt={"arrow back"}
                src={arrowBack}
                style={{ marginRight: "10px" }}
              />
              QUAY LẠI
            </button>
          </div>
        </div>
        <Table
          onRow={(record, index) => ({
            onClick: () => {
              fetchOrderDetail(record.id);
              setDataOrderModal(record);
              setIsShowModal(true);
            },
          })}
          loading={isLoading}
          rowKey="order_number"
          columns={columns}
          dataSource={orderListFilter}
          className="table-refund"
          pagination={false}
          scroll={{ y: 500 }}
        />
      </div>
      <Modal
        centered={true}
        destroyOnClose={true}
        className="modal-ready-ship"
        open={isShowModal}
        onCancel={() => {
          setIsShowModal(false);
          setProcessingNotRefundDelivery(false);
        }}
        title={renderTitleModal()}
        width={695}
        footer={renderFooterRefunds(
          orderDetail,
          isLoading,
          handleSubmitRefund,
          confirmNotRefund
        )}
      >
        {renderModalData(orderDetail, dataOrderModal)}
      </Modal>

      {/* ===================Modal COOKING==================== */}
      <ModalCooking
        title={renderTitleModal()}
        isShowModalCooking={isProcessingNotRefundDelivery}
        closeModalCooking={() => setProcessingNotRefundDelivery(false)}
        detailOrder={orderDetail}
        dataOrderModal={dataOrderModal}
        timeToEnd={timeToEnd}
        shipperList={shipperList}
        handleSelectShipper={handleSelectShipper}
        selectedShipper={selectedShipper}
        handleSubmitReadyToShip={handleSubmitReadyToShip}
        loadingButton={loadingButton}
      />
    </div>
  );
};

export default RefundPage;
