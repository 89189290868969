import { formatNumberWithCommas } from "utils/format";

export const ColumnsBatchInvoices = [
  {
    title: "Name",
    dataIndex: "invoice_number",
    width: 150,
    align: "center",
  },
  {
    title: "Sales",
    dataIndex: "subtotal",
    align: "center",
    render: (value) => formatCurrency(value)
  },
  {
    title: "Tax",
    dataIndex: "total_tax",
    align: "center",
    render: (value) => formatCurrency(value)
  },
  {
    title: "Tip",
    dataIndex: "tip_amount",
    align: "center",
    width: 125,
    render: (value) => formatCurrency(value)
  },
  {
    title: "Total Sales",
    dataIndex: "grand_total",
    align: "center",
    render: (value) => (
      <span>{formatCurrency(formatNumberWithCommas(value))}</span>
    ),
  },
];

export const ColumnsPaymentMethods = [
  {
    title: "Payments",
    dataIndex: "payments",
    align: "center",
    width: 300,
    render: (value) => (
      <span style={{ fontWeight: 500 }}>{renderTitlePayment(value)}</span>
    ),
  },
  {
    title: "Amount",
    dataIndex: "value",
    render: (value) => <span style={{ fontWeight: 500 }}>{formatCurrency(value)}</span>,
  },
];

export const paymentClassNames = {
  credit_card: "row-Credit-Card",
  cash: "row-Cash",
  discount: "row-Discount",
};

export const renderTitlePayment = (value) => {
  const titles = {
    credit_card: "Credit Card",
    cash: "Cash",
    discount: "Discount",
  };
  return titles[value];
};

export const formatCurrency = (value) => {
  return `$${value}`;
};
