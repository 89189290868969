import React, { useState, useRef, useEffect } from "react";
import Header from "components/Header";
import MenuBar from "components/MenuBar";
import CustomButton from "components/CustomButton";
import { useTranslation } from "react-i18next";
import { Row, Col, Switch, Form, Input, Select, Button, Spin } from "antd";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
import PopupAction from "components/PopupAction";
import RadioOption from "components/atom/Radio";
import CheckBoxOption from "components/atom/CheckBoxOption";
import { apiGetCategory } from "apis/Category";
import {
  apiMerchantCreateProduct,
  apiGetProductDetail,
  apiMerchantDeleteProduct,
  apiMerchantUpdateProduct,
  apiGetKitchenStation,
} from "apis/Product";
import { useParams, useHistory } from "react-router-dom";
import Loading from "components/Loading";
import { AlertContext } from "handlers/alertContext";
import { useContext } from "react";

const { Option } = Select;

import "./index.scss";
import UploadImage from "components/atom/UploadImage/UploadImage";

const Index = () => {
  const { t } = useTranslation();

  const [isToggled, setIsToggled] = useState(false);
  const [listImage, setListImage] = useState([]);
  const [is_in_stock, setIsInStock] = useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [menuList, setMenuList] = useState([]);
  const [stations, setStations] = useState([]);

  const dineInRef = useRef();
  const takeAwayRef = useRef();
  const openPriceRef = useRef();

  const history = useHistory();
  const { id: productId } = useParams();
  const { openModal } = useContext(AlertContext);

  const location = useLocation();
  const pathname = location?.pathname;

  const refPopupDelete = useRef();
  const refPopupEdit = useRef();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const dineIn = dineInRef?.current?.getValue?.() ? "dine_in" : null;
    const takeAway = takeAwayRef?.current?.getValue?.() ? "online" : null;
    const display_platforms = [];
    if (dineIn) display_platforms.push(dineIn);
    if (takeAway) display_platforms.push(takeAway);

    const media_gallery_entries = listImage.map((img) => {
      if (img?.id) {
        return {
          id: img?.id,
          media_type: "image",
        };
      }
      return {
        media_type: "image",
        label: img?.name,
        position: 0,
        disabled: false,
        types: ["image", "small_image", "thumbnail"],
        file: img?.name,
        content: {
          data: {
            base64_encoded_data: img?.thumbUrl?.replace?.(
              "data:image/png;base64,",
              ""
            ),
            name: img?.name,
            type: "image/png",
          },
        },
      };
    });

    const payload = {
      ...values,
      display_platforms,
      is_in_stock,
      price: formatPrice(values?.price),
      status: values?.status ? 1 : 2,
      open_price: false,
      media_gallery_entries,
    };
    setLoading(true);

    if (productId) {
      apiMerchantUpdateProduct(payload).then((res) => {
        setLoading(false);
        if (res?.errors) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        history.push("/items");
      });
    } else {
      apiMerchantCreateProduct(payload).then((res) => {
        setLoading(false);
        if (res?.errors) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        history.push("/items");
      });
    }
  };

  const deleteProduct = () => {
    setLoading(true);
    apiMerchantDeleteProduct({ id: productId }).then((res) => {
      setLoading(false);
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      history.push("/items");
    });
  };

  const getKitchenStation = () => {
    apiGetKitchenStation().then((res) => {
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      setStations(res?.data?.getKitchenStations ?? []);
    });
  };

  useEffect(() => {
    getKitchenStation();
    apiGetCategory({
      currentPage: 1,
      pageSize: 10,
      field: "id",
      position: "DESC",
    }).then((res) => {
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      setMenuList(res?.data?.merchantCategories?.items ?? []);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!productId) return;
    const getDetail = () => {
      setLoading(true);
      apiGetProductDetail({ id: parseInt(productId) }).then(async (res) => {
        const detail = res?.data?.merchantProduct;
        if (res?.errors) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        form.setFieldsValue({
          name: detail?.name,
          description: detail?.description_plain_text,
          status: detail?.status == 1 ? true : false,
          price: detail?.price?.regularPrice?.amount?.value,
          kitchen_station: detail?.kitchen_station,
          sku: detail?.sku,
          category_id: detail?.categories?.[0]?.id,
          quantity: detail?.qty,
        });

        setIsToggled(detail?.status == 1 ? true : false);
        setIsInStock(detail?.stock_status == "OUT_OF_STOCK" ? false : true);

        openPriceRef?.current?.setValue(detail?.open_price ? true : false);
        if (detail?.display_platforms?.includes?.("dine_in")) {
          dineInRef?.current?.setValue(true);
        }
        if (detail?.display_platforms?.includes?.("online")) {
          takeAwayRef?.current?.setValue(true);
        }
        setListImage();

        const fileList = detail?.media_gallery_entries?.map?.((obj, index) => {
          return {
            uid: obj?.uid,
            name: obj?.label,
            status: "done",
            url: detail?.media_gallery?.[index]?.url,
            id: obj?.id,
          };
        });

        setListImage(fileList);
      });
    };

    getDetail();
    setLoading(false);
  }, [productId, menuList]);

  return (
    <>
      <Loading loading={isLoading} />
      <Header />
      <div className="container-box body_history">
        <MenuBar
          title={
            pathname?.includes?.("edit_item")
              ? "Menu Management / Menus / Edit Item"
              : "Menu Management / Menus / Create News Item"
          }
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            status: isToggled,
            channels: ["Dine-in"],
            stock_status: "in_stock",
          }}
        >
          {pathname?.includes?.("edit_item") ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <CustomButton
                style={{
                  background: "#FEF1F2",
                  color: "#E02D3C",
                }}
                title="Delete"
                onClick={() => refPopupDelete.current?.showModal?.()}
              />
              <CustomButton
                style={{ background: "#F8F9FC", color: "#333", marginLeft: 16 }}
                title="Cancel"
                onClick={() => refPopupEdit.current?.showModal?.()}
              />

              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "var(--primary-6)",
                  color: "#fff",
                  marginLeft: 16,
                  height: 35,
                  width: 70,
                  fontWeight: "600",
                }}
              >
                Save
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <CustomButton
                style={{ background: "#FEF1F2", color: "#E02D3C" }}
                title="Cancel"
                onClick={() => history.push("/items")}
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "var(--primary-6)",
                  color: "#fff",
                  marginLeft: 16,
                  height: 35,
                  width: 70,
                  fontWeight: "600",
                }}
              >
                Save
              </Button>
            </div>
          )}

          <div className="menu_new">
            <Row gutter={16}>
              <Col span={12}>
                <p className="menu_new_name">Category</p>
                <Form.Item
                  name="category_id"
                  rules={[{ required: true, message: "Please select one!" }]}
                >
                  <Select
                    className="custom-select"
                    style={{ border: "none", boxShadow: "none" }}
                    placeholder="Select a group"
                    defaultActiveFirstOption
                  >
                    {menuList?.map?.((m) => (
                      <Option key={m?.id} value={m?.id}>
                        {m?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <p className="menu_new_name">Item ID</p>
                <Form.Item
                  name="sku"
                  rules={[
                    {
                      required: true,
                      message: "Please enter sku",
                    },
                  ]}
                >
                  <Input
                    disabled={productId ? true : false}
                    placeholder="Item ID"
                  />
                </Form.Item>
              </Col>
            </Row>

            <p className="menu_new_name">Name</p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the Name",
                },
              ]}
            >
              <Input placeholder="Item name" />
            </Form.Item>

            <p className="menu_new_name">Status</p>
            <Form.Item
              className="custom-form-item"
              name="status"
              valuePropName="checked"
            >
              <Switch
                checked={isToggled}
                onChange={(checked) => setIsToggled(checked)}
                style={{
                  backgroundColor: isToggled ? "var(--primary-6)" : "#d9d9d9",
                }}
                size="default"
              />
            </Form.Item>

            <p className="menu_new_name">Image ({listImage?.length}/5)</p>
            <UploadImage listImage={listImage} setListImage={setListImage} />

            <p className="menu_new_name">Description (optional)</p>
            <Form.Item name="description">
              <Input placeholder="Item description" />
            </Form.Item>

            <p className="menu_new_name">Price</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                style={{ width: "35%" }}
                name="price"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("Please enter price");
                      }
                      const formatedValue = formatPrice(value);
                      if (formatedValue >= 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Please enter a number 0 or greater in this field"
                      );
                    },
                  },
                ]}
              >
                <NumericFormat
                  prefix="$"
                  allowLeadingZeros
                  thousandSeparator=","
                  decimalScale={2}
                  placeholder="Item price"
                  fixedDecimalScale
                />
              </Form.Item>
              {/* <div style={{ marginTop: -24, marginLeft: 50 }}>
                <CheckBoxOption ref={openPriceRef} name="Open price" />
              </div> */}
            </div>
            <p className="menu_new_name">Quantity</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                style={{ width: "35%" }}
                name="quantity"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("Please enter Quantity");
                      }
                      const formatedValue = formatPrice(value);
                      if (formatedValue >= 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Please enter a number 0 or greater in this field"
                      );
                    },
                  },
                ]}
              >
                <NumericFormat
                  prefix=""
                  allowLeadingZeros
                  thousandSeparator=","
                  decimalScale={0}
                  placeholder="Quantity..."
                  fixedDecimalScale
                />
              </Form.Item>
              {/* <div style={{ marginTop: -24, marginLeft: 50 }}>
                <CheckBoxOption ref={openPriceRef} name="Open price" />
              </div> */}
            </div>
            <p className="menu_new_name">Channel</p>
            <CheckBoxOption ref={dineInRef} name="Dine-in" />
            <CheckBoxOption ref={takeAwayRef} name="Take away" />

            <p className="menu_new_name">Inventory</p>
            <span style={{ color: "var(--text-secondary)", marginTop: 16 }}>
              Stock status
            </span>
            <RadioOption
              name="In stock"
              checked={is_in_stock}
              onChecked={() => setIsInStock(true)}
            />
            <RadioOption
              name="Out of stock"
              checked={!is_in_stock}
              onChecked={() => setIsInStock(false)}
            />
            <p className="menu_new_name">Kitchen Station</p>
            <Form.Item
              name="kitchen_station"
              rules={[
                {
                  required: true,
                  message: "Please select an kitchen station",
                },
              ]}
            >
              <Select
                className="custom-select"
                style={{ border: "none", boxShadow: "none" }}
              >
                {stations?.map?.((m) => (
                  <Option key={m?.printer_id} value={m?.printer_id}>
                    {m?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>

        <PopupAction
          title="Delete this Item?"
          content={"Once deleted it, cannot be undone"}
          onConfirm={deleteProduct}
          onCancel={() => {}}
          ref={refPopupDelete}
        />
        <PopupAction
          title="Cancel editing?"
          content={"Your changes won't be saved"}
          onConfirm={() => {
            history.push("/items");
          }}
          onCancel={() => {}}
          ref={refPopupEdit}
          titleActionLeft="Keep editing"
          titleActionRight="Confirm cancel"
        />
      </div>
    </>
  );
};

export default Index;

function formatPrice(value) {
  if (typeof value === "number") return value;
  return +value?.slice(1).replaceAll(",", "");
}
