import React from "react";

export default function PickUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        d="M14.0865 5C15.3503 5 16.676 5.90964 17.1444 8.12007L17.9133 14.3147C18.4789 18.3535 16.2075 20 13.1585 20H4.86853C1.81062 20 -0.531424 18.8629 0.104905 14.3147L0.882639 8.12007C1.28034 5.84597 2.65022 5 3.93171 5H14.0865ZM6.097 8.32929C5.60889 8.32929 5.21321 8.73655 5.21321 9.23893C5.21321 9.74131 5.60889 10.1486 6.097 10.1486C6.5851 10.1486 6.98079 9.74131 6.98079 9.23893L6.9739 9.12483C6.91936 8.67631 6.54755 8.32929 6.097 8.32929ZM11.8858 8.32929C11.3977 8.32929 11.002 8.73655 11.002 9.23893C11.002 9.74131 11.3977 10.1486 11.8858 10.1486C12.3739 10.1486 12.7696 9.74131 12.7696 9.23893C12.7696 8.73655 12.3739 8.32929 11.8858 8.32929Z"
        fill="#1D2433"
      />
      <path
        opacity="0.4"
        d="M13.9739 4.77432C13.977 4.85189 13.9621 4.92913 13.9303 5H12.4932C12.4654 4.92794 12.4506 4.85153 12.4497 4.77432C12.4497 2.85682 10.8899 1.30238 8.96575 1.30238C7.04164 1.30238 5.48184 2.85682 5.48184 4.77432C5.49502 4.84898 5.49502 4.92535 5.48184 5H4.00989C3.9967 4.92535 3.9967 4.84898 4.00989 4.77432C4.12172 2.10591 6.32499 0 9.00494 0C11.6849 0 13.8882 2.10591 14 4.77432H13.9739Z"
        fill="#1D2433"
      />
    </svg>
  );
}
