import React from "react";

export default function DineInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 7.5C22 5.57 17.52 4 12 4C6.48 4 2 5.57 2 7.5C2 9.31 5.95 10.81 11 10.98V15H9.35C8.53 15 7.8 15.5 7.49 16.26L6.5 18.73C6.27 19.34 6.71 20 7.37 20C7.75 20 8.09 19.77 8.23 19.42L9.2 17H14.8L15.77 19.42C15.91 19.77 16.25 20 16.63 20C17.29 20 17.74 19.34 17.49 18.73L16.5 16.26C16.2 15.5 15.46 15 14.65 15H13V10.98C18.05 10.81 22 9.31 22 7.5Z"
        fill="#1D2433"
      />
    </svg>
  );
}
