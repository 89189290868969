import React, { useState, useRef } from "react";
import { Form, Input, Button, Switch, Spin } from "antd";
import Header from "components/Header";
import MenuBar from "components/MenuBar";
import CustomButton from "components/CustomButton";
import { useTranslation } from "react-i18next";
import TimePicker from "./TimePicker";
import PopupTimePicker from "./PopupTimePicker";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PopupAction from "components/PopupAction";
import {
  apiMerchantCreateMenu,
  apiGetMenuDetail,
  apiMerchantUpdateMenu,
  apiMerchantDeleteMenu,
} from "apis/Menu";
import { useParams, useHistory } from "react-router-dom";
import { AlertContext } from "handlers/alertContext";
import { useContext } from "react";
import "./index.scss";

const Index = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isToggled, setIsToggled] = useState(false);

  const [startTime, setStartTime] = useState(moment().format("hh:mm A"));
  const [endTime, setEndTime] = useState(moment().format("hh:mm A"));
  const [activeDays, setActiveDays] = useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();
  const { openModal } = useContext(AlertContext);

  const timePickerModalRef = useRef();
  const refPopupDelete = useRef();
  const refPopupEdit = useRef();

  const location = useLocation();
  const pathname = location?.pathname;

  const { id: menuId } = useParams();

  React.useEffect(() => {
    if (menuId) {
      setLoading(true);
      apiGetMenuDetail({ id: parseInt(menuId) }).then((res) => {
        const detail = res?.data?.merchantMenu;
        const dayMap = {
          mon_active: "Mon",
          tue_active: "Tue",
          wed_active: "Wed",
          thu_active: "Thu",
          fri_active: "Fri",
          sat_active: "Sat",
          sun_active: "Sun",
        };

        const _activeDays = Object.keys(detail)
          .filter((key) => key.endsWith("_active") && detail[key])
          .map((key) => dayMap[key]);
        setActiveDays(_activeDays);

        form.setFieldsValue({
          name: detail?.name,
          description: detail?.description,
          is_active: detail?.is_active,
        });
        setStartTime(detail?.start_time);
        setEndTime(detail?.end_time);
        setIsToggled(detail?.is_active);
        setLoading(false);
      });
    }
  }, [menuId]);

  const clickActiveDay = (day) => {
    const foundDay = activeDays.find((d) => d == day);
    if (!foundDay) {
      setActiveDays([...activeDays, day]);
    } else {
      setActiveDays([...activeDays].filter((d) => d !== foundDay));
    }
  };

  const clickStartTime = () => {
    timePickerModalRef.current?.openStartTime?.();
  };

  const clickEndTime = () => {
    timePickerModalRef.current?.openEndTime?.();
  };

  const handleToggle = (checked) => {
    setIsToggled(checked);
  };

  const onDoneEndTime = (time) => {
    setEndTime(time);
  };
  const onDoneStartTime = (time) => {
    setStartTime(time);
  };

  function convertTimeFormat(time) {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
    hours = ("0" + hours).slice(-2);
    return `${hours}:${minutes} ${modifier}`;
  }

  const onFinish = (values) => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const payload = days.reduce(
      (acc, day) => ({
        ...acc,
        [`${day.toLowerCase()}_active`]: activeDays.includes(day),
      }),
      {
        ...values,
        start_time: convertTimeFormat(startTime?.toUpperCase()),
        end_time: convertTimeFormat(endTime?.toUpperCase()),
      }
    );
    setLoading(true);
    if (menuId) {
      apiMerchantUpdateMenu({
        ...payload,
        id: parseInt(menuId),
      }).then((res) => {
        setLoading(false);
        if (res?.errors) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        history.push("/menu");
      });
    } else {
      apiMerchantCreateMenu(payload).then((res) => {
        setLoading(false);
        if (res?.errors) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        history.push("/menu");
      });
    }
  };
  const deleteMenu = () => {
    setLoading(true);
    apiMerchantDeleteMenu({ id: menuId }).then((res) => {
      setLoading(false);
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      history.push("/menu");
    });
  };

  return (
    <>
      {isLoading && (
        <div className="loading_container">
          <Spin />
        </div>
      )}
      <Header />
      <div className="container-box body_history">
        <MenuBar
          title={
            pathname?.includes?.("edit_menu")
              ? "Menu Management / Menus / Edit Menu"
              : "Menu Management / Menus / Create News Menu"
          }
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            name: "",
            description: "",
            status: isToggled,
          }}
        >
          {pathname?.includes?.("edit_menu") ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <CustomButton
                style={{
                  background: "var(--error-1-bg)",
                  color: "var(--error-2-default)",
                }}
                title="Delete"
                onClick={() => refPopupDelete.current?.showModal?.()}
              />
              <CustomButton
                style={{
                  background: "var(--neutral-base)",
                  color: "#333",
                  marginLeft: 16,
                }}
                title="Cancel"
                onClick={() => refPopupEdit.current?.showModal?.()}
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "var(--primary-6)",
                  color: "#fff",
                  marginLeft: 16,
                  height: 36,
                  width: 70,
                  fontWeight: "600",
                }}
              >
                Save
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <CustomButton
                style={{
                  background: "var(--error-1-bg)",
                  color: "var(--error-2-default)",
                }}
                title="Cancel"
                onClick={() => history.push("/menu")}
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "var(--primary-6)",
                  color: "#fff",
                  marginLeft: 16,
                  height: 35,
                  width: 70,
                  fontWeight: "600",
                }}
              >
                Save
              </Button>
            </div>
          )}

          <div className="menu_new">
            <p className="menu_new_name">Name</p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the menu name",
                },
              ]}
            >
              <Input placeholder="New menu name" />
            </Form.Item>

            <p className="menu_new_name">Status</p>
            <Form.Item
              className="custom-form-item"
              name="is_active"
              valuePropName="checked"
            >
              <Switch
                checked={isToggled}
                onChange={handleToggle}
                style={{
                  backgroundColor: isToggled ? "var(--primary-6)" : "#d9d9d9",
                }}
                size="default"
              />
            </Form.Item>

            <p className="menu_new_name">Description (optional)</p>
            <Form.Item name="description">
              <Input placeholder="Description of menu" />
            </Form.Item>

            <TimePicker
              clickEndTime={clickEndTime}
              clickStartTime={clickStartTime}
              startTime={startTime}
              endTime={endTime}
              clickActiveDay={clickActiveDay}
              activeDays={activeDays}
            />
          </div>
        </Form>

        <PopupAction
          title="Delete this menu?"
          content={"Once deleted it, cannot be undone"}
          onConfirm={deleteMenu}
          onCancel={() => {}}
          ref={refPopupDelete}
        />
        <PopupAction
          title="Canel editing?"
          content={"Your change won't be saved"}
          onConfirm={() => {
            history.push("/menu");
          }}
          onCancel={() => {}}
          ref={refPopupEdit}
          titleActionLeft="Keep editing"
          titleActionRight="Confirm cancel"
        />
        <PopupTimePicker
          ref={timePickerModalRef}
          onDoneStartTime={onDoneStartTime}
          onDoneEndTime={onDoneEndTime}
        />
      </div>
    </>
  );
};

export default Index;
