export const columns = [
  {
    title: "Batch ID",
    dataIndex: "id",
    key: "id",
    render: (entity_id) => {
      return (
        <div style={{ color: "var(--text-primary)", fontSize: 16 }}>
          #{entity_id}
        </div>
      );
    },
  },
  {
    title: "Date Time",
    dataIndex: "created_at",
    key: "created_at",
    render: (created) => {
      return <div style={{ fontSize: 16 }}>{created}</div>;
    },
    sorter: true,
  },
  {
    title: "Invoice Number",
    dataIndex: "invoice_number",
    key: "invoice_number",
    render: (invoice_number) => {
      return (
        <div
          style={{
            fontWeight: "600",
            fontSize: 17,
          }}
        >
          {invoice_number}
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      return <div>{status}</div>;
    },
  },
  {
    title: "Payment",
    dataIndex: "payment",
    key: "payment",
    render: (payment) => {
      return <div>{payment?.method}</div>;
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type) => {
      return <div>{type?.toUpperCase()}</div>;
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (total) => {
      return (
        <div
          style={{
            fontWeight: "600",
            fontSize: 17,
          }}
        >
          $ {total}
        </div>
      );
    },
  },
];
