import { Text } from "components/atom/Text";
import React from "react";

export default function ButtonSubmit({ onClick, title }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 56,
        background: "var(--primary-6)",
        marginTop: 20,
        borderRadius: 8,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Text
        style={{
          fontSize: 18,
          fontWeight: "600",
          color: "var(--primary-1)",
        }}
      >
        {title}
      </Text>
    </div>
  );
}
