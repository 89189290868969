import { App, Col, Row, Spin, Modal } from "antd";
import { Button } from "../../components/atom/Button";
import { BoldText, TextDark } from "../../components/atom/Text/index";
import React, { useEffect, useState } from "react";
import { Colors } from "themes/colors";
import Barcode from "react-barcode";
import {
  ButtonContainer,
  Container,
  DividedDashed,
  DividedSolid,
  RowStyled,
  text24,
  BarCodeContainer,
  text16,
  text16Bold,
  text16W,
} from "./styled";
import { formatNumberWithCommas } from "utils/format";
import { getDetailBillOrder } from "apis/Order";
import { useHistory, useLocation } from "react-router-dom";
import { usePrinter } from "handlers/printerContext";
// import { usePrinter } from "context/printerContext";
const CURRENTCY = "$";
function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

export default function index() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const onGetData = (data) => {
    setLoading(true);
    getDetailBillOrder(data)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setLoading(false));
  };
  const searchParams = useSearchParams();
  const orderId = searchParams.get("orderId");
  const order_ID = searchParams.get("order_id");
  const navigation = useHistory();
  useEffect(() => {
    if (orderId !== null && orderId !== "undefined") {
      onGetData({ id: orderId });
    }
  }, [orderId]);
  useEffect(() => {
    if (order_ID !== null && order_ID !== "undefined") {
      onGetData({ id: orderId });
    }
  }, [order_ID]);
  const { print, connectionStatus } = usePrinter();
  const PrintBill = () => {
    if (connectionStatus === "Connected") {
      print();
      Modal.success({
        title: "Print bill Success",
        content: "Please go to printer to take the bill!",
        centered: true,
        onOk: () => {
          navigation.goBack();
        },
      });
    } else {
      Modal.error({
        title: "Print bill Failed",
        content: "Please set up printer then try again!",
        centered: true,
        onOk: () => {
          navigation.goBack();
        },
      });
    }
  };
  return (
    <Container>
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          zIndex: -1,
          background: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <Spin size="large" tip="Loading..." />}
      </div>

      {!loading && (
        <>
          <ButtonContainer>
            <Button
              style={{
                height: 56,
                width: 116,
                background: "black",
                border: `1px solid var(--primary-6)`,
                display: "flex",
              }}
              onClick={() => navigation.goBack()}
            >
              <TextDark
                style={{
                  color: "var(--primary-6)",
                  fontWeight: "600",
                }}
              >
                GO BACK
              </TextDark>
            </Button>
          </ButtonContainer>
          <RenderBill data={data?.orderDetail} />
          <ButtonContainer isRight>
            <Button
              style={{
                height: 56,
                width: 116,
                display: "flex",
                background: "var(--primary-6)",
                border: `1px solid var(--primary-6)`,
              }}
              onClick={PrintBill}
            >
              <TextDark
                style={{
                  color: Colors.black,
                  fontWeight: "600",
                }}
              >
                Print
              </TextDark>
            </Button>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
}

const RenderBill = ({ data }) => {
  const totalDiscount =
    data?.discount?.length > 0
      ? data?.discount?.reduce((total, discount) => {
          total += discount.amount.value;
          return total;
        }, 0)
      : 0;
  return (
    <div
      style={{
        width: "320px",
        borderRadius: 8,
        background: "white",
        paddingTop: 20,
        paddingInline: 16,
        overflow: "auto",
      }}
    >
      <div id="billHeader">
        <TextDark style={{ ...text24, fontWeight: "600" }}>
          {data?.restaurant_name}
        </TextDark>
        <TextDark style={{ ...text16W, marginTop: 16 }}>
          {data?.restaurant_address}
        </TextDark>
        <TextDark style={{ ...text16W, marginTop: 8 }}>
          Hotline: {data?.restaurant_phone}
        </TextDark>

        <DividedDashed />
        <TextDark
          style={{
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          RECEIPT
        </TextDark>

        <RowStyled>
          <TextDark>
            <BoldText>Date: </BoldText>
            {data?.created_at && data?.created_at.split(" ")[0]}
          </TextDark>
          <TextDark>
            <BoldText>Time: </BoldText>
            {data?.created_at && data?.created_at.split(" ")[1]}
          </TextDark>
        </RowStyled>
        <RowStyled>
          <TextDark>{data?.order_source}</TextDark>
          <TextDark>
            <BoldText>Bill: </BoldText>
            {data?.order_number}
          </TextDark>
        </RowStyled>
        {data?.serve_name || data?.table ? (
          <RowStyled>
            {data?.table ? (
              <TextDark>
                <BoldText>Table: </BoldText>
                {data?.table}
              </TextDark>
            ) : (
              <></>
            )}
            {data?.serve_name ? (
              <TextDark
                style={{
                  width: 150,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "right",
                }}
              >
                <BoldText>Server: </BoldText>
                {data?.serve_name}
              </TextDark>
            ) : (
              <></>
            )}
          </RowStyled>
        ) : (
          <></>
        )}
        <RowStyled>
          <TextDark>
            <BoldText>Customer: </BoldText>
            {(data?.firstname && data?.lastname
              ? data?.firstname + " " + data?.lastname
              : data?.firstname) || "Guest"}
          </TextDark>
        </RowStyled>
        <DividedDashed />
      </div>
      <div id="billContent">
        {data?.items?.map((item, index) => {
          return (
            <>
              <RowStyled key={index}>
                <Col style={{ textAlign: "left", width: 20 }}>
                  <span>{item?.qty}</span>
                </Col>
                <Col style={{ flex: 1 }}> {item?.name}</Col>
                <Col style={{ textAlign: "end", width: 70 }}>
                  {CURRENTCY}
                  {item?.price.toFixed(2)}
                </Col>
              </RowStyled>
              {item?.options?.map((option, idx) => {
                return (
                  <RowStyled
                    key={`${index}-${idx}`}
                    style={{ paddingLeft: 20 }}
                  >
                    <TextDark style={text16}>• {option?.name}</TextDark>
                  </RowStyled>
                );
              })}
            </>
          );
        })}
      </div>
      <div id="billFooter">
        <DividedDashed />

        <RowStyled>
          <TextDark style={text16}>Subtotal:</TextDark>
          <TextDark>
            {CURRENTCY} {data?.total?.subtotal?.value?.toFixed(2)}
          </TextDark>
        </RowStyled>
        <RowStyled>
          <TextDark style={text16}>Discount:</TextDark>
          <TextDark>
            - {CURRENTCY} {totalDiscount.toFixed(2)}
          </TextDark>
        </RowStyled>
        {data?.total?.total_tax?.value && (
          <RowStyled align={"middle"}>
            <TextDark style={text16}>Tax</TextDark>
            <TextDark>
              {CURRENTCY} {data?.total?.total_tax?.value?.toFixed(2)}
            </TextDark>
          </RowStyled>
        )}

        {data?.total?.service_charge_amount?.value && (
          <RowStyled align={"middle"}>
            <TextDark style={text16}>
              Service Charge{" "}
              {`(${data?.total?.service_charge_amount?.value || 10}%):`}
            </TextDark>
            <TextDark>
              {CURRENTCY}{" "}
              {data?.total?.service_charge_amount?.value?.toFixed(2)}
            </TextDark>
          </RowStyled>
        )}
        <RowStyled align={"middle"}>
          <TextDark style={text16}>Total:</TextDark>
          <TextDark>
            {CURRENTCY}{" "}
            {data?.total?.subtotal?.value -
              totalDiscount +
              data?.total?.total_tax?.value}
          </TextDark>
        </RowStyled>
        <DividedDashed />
        <RowStyled align={"middle"}>
          <TextDark style={text16}>Tip:</TextDark>
          <TextDark>
            {CURRENTCY} {data?.tip_amount?.value?.toFixed(2)}
          </TextDark>
        </RowStyled>
        <RowStyled align={"middle"}>
          <TextDark style={text16}>Grand Total:</TextDark>
          <TextDark>
            {CURRENTCY} {data?.grand_total?.toFixed(2)}
          </TextDark>
        </RowStyled>
        <DividedDashed />
        <RowStyled align={"middle"}>
          <TextDark style={text16}>Payment Method:</TextDark>
          <TextDark>{data?.payment_method}</TextDark>
        </RowStyled>

        {data?.payment_methods &&
          data?.payment_methods[0]?.additional_data[1]?.value && (
            <RowStyled align={"middle"}>
              <TextDark style={text16}>Cart Type:</TextDark>
              <TextDark>
                {data?.payment_methods[0]?.additional_data[1]?.value}
              </TextDark>
            </RowStyled>
          )}
        {data?.payment_methods &&
          data?.payment_methods[0]?.additional_data[0]?.value && (
            <RowStyled align={"middle"}>
              <TextDark style={text16}>Last 4 Digits:</TextDark>
              <TextDark>
                {data?.payment_methods[0]?.additional_data[0]?.value}
              </TextDark>
            </RowStyled>
          )}
        <DividedDashed />
        <TextDark>
          <TextDark style={text16}>
            Signature:_________________________
          </TextDark>
        </TextDark>
        <DividedDashed />
        <TextDark
          style={{
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Customer Copy
        </TextDark>
        <TextDark style={{ marginTop: 10 }}>
          Thank you for dining with us!
        </TextDark>
        <TextDark style={{ marginTop: 10 }}>
          Feedback/Contact us: {data?.feedback_url}
        </TextDark>

        <BarCodeContainer>
          <Barcode value={data?.order_number} />
        </BarCodeContainer>
      </div>
    </div>
  );
};
