import React from "react";

export default function BillIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M17.4368 35.3373C18.2634 35.6707 19.1318 35.8357 20.0001 35.8357C20.8684 35.8357 21.7368 35.669 22.5651 35.3357L26.1451 33.869C26.6934 33.6457 27.3284 33.6623 27.8784 33.9157L29.1268 34.5107C30.0084 34.9423 31.0301 34.8873 31.8618 34.369C32.6984 33.8457 33.1984 32.9457 33.1968 31.9607L33.1668 11.5291C33.1668 6.9174 30.5251 4.16406 26.0951 4.16406H13.9301C9.54513 4.16406 6.82013 6.98573 6.82013 11.5291L6.80347 31.9607C6.80347 32.9457 7.2968 33.8457 8.12513 34.3707C8.95513 34.8923 9.98013 34.954 10.8718 34.529L12.1301 33.9173C12.6835 33.6623 13.3151 33.6457 13.8635 33.869L17.4368 35.3373Z"
        fill="var(--text-primary)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7158 17.5797H15.2874C14.5974 17.5797 14.0374 17.0197 14.0374 16.3296C14.0374 15.6396 14.5974 15.0796 15.2874 15.0796H24.7158C25.4058 15.0796 25.9658 15.6396 25.9658 16.3296C25.9658 17.0197 25.4058 17.5797 24.7158 17.5797ZM24.7158 24.913H15.2874C14.5974 24.913 14.0374 24.353 14.0374 23.663C14.0374 22.973 14.5974 22.413 15.2874 22.413H24.7158C25.4058 22.413 25.9658 22.973 25.9658 23.663C25.9658 24.353 25.4058 24.913 24.7158 24.913Z"
        fill="var(--neutral-primary)"
      />
    </svg>
  );
}
