import { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Switch, Checkbox, Spin, Select } from "antd";
import Header from "components/Header";
import MenuBar from "components/MenuBar";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PopupAction from "components/PopupAction";
import CustomButton from "components/CustomButton";
import {
  apiMerchantCreateCategory,
  apiGetCategoryDetail,
  apiMerchantUpdateCategory,
  apiMerchantDeleteCategory,
} from "apis/Category";
import { apiGetKitchenStation } from "apis/Product";
import { apiGetMenu } from "apis/Menu";
import { useParams, useHistory } from "react-router-dom";
import { AlertContext } from "handlers/alertContext";
import { useContext } from "react";

import "./index.scss";

const Index = () => {
  const { t } = useTranslation();
  const [isToggled, setIsToggled] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { id: categoryId } = useParams();
  const [form] = Form.useForm();
  const { openModal } = useContext(AlertContext);
  const [stations, setStations] = useState([]);

  const location = useLocation();
  const pathname = location?.pathname;
  const refPopupDelete = useRef();
  const refPopupEdit = useRef();

  const handleToggle = (checked) => {
    setIsToggled(checked);
  };

  const onFinish = (values) => {
    const payload = {
      ...values,
    };

    setLoading(true);
    if (categoryId) {
      apiMerchantUpdateCategory({
        ...payload,
        id: parseInt(categoryId),
      }).then((res) => {
        setLoading(false);
        if (res?.errors && res?.errors?.length > 0) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        history.push("/category");
      });
    } else {
      apiMerchantCreateCategory(payload).then((res) => {
        setLoading(false);
        if (res?.errors && res?.errors?.length > 0) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        history.push("/category");
      });
    }
  };
  const deleteCategory = () => {
    setLoading(true);
    apiMerchantDeleteCategory({ id: categoryId }).then((res) => {
      setLoading(false);
      if (res?.errors && res?.errors?.length > 0) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      history.push("/category");
    });
  };

  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      apiGetCategoryDetail({ id: parseInt(categoryId) }).then((res) => {
        if (res?.errors && res?.errors?.length > 0) {
          openModal(res?.errors?.[0]?.message);
          return;
        }
        const detail = res?.data?.merchantCategory;
        form.setFieldsValue({
          name: detail?.name,
          description: detail?.description,
          is_active: detail?.is_active,
          menu_ids: detail?.menus?.map?.((m) => m?.entity_id),
          kitchen_station: detail?.kitchen_station,
        });
        setIsToggled(detail?.is_active);
        setLoading(false);
      });
    }
  }, [categoryId, menuList]);
  const getKitchenStation = () => {
    apiGetKitchenStation().then((res) => {
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      setStations(res?.data?.getKitchenStations ?? []);
    });
  };

  useEffect(() => {
    getKitchenStation();
    apiGetMenu({
      search: "",
      currentPage: 1,
      field: "entity_id",
      position: "DESC",
      pageSize: 30,
    }).then((res) => {
      setLoading(false);
      if (res?.errors && res?.errors?.length > 0) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      setMenuList(res?.data?.merchantMenus?.items ?? []);
    });
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading_container">
          <Spin />
        </div>
      )}
      <Header />
      <div className="container-box body_history">
        <MenuBar
          title={
            pathname?.includes?.("edit_category")
              ? "Menu Management / Categories / Edit Category"
              : "Menu Management / Categories / Create New Category"
          }
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            status: isToggled,
          }}
        >
          {pathname?.includes?.("edit_category") ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <CustomButton
                style={{
                  background: "var(--error-1-bg)",
                  color: "var(--error-2-default)",
                }}
                title="Delete"
                onClick={() => refPopupDelete.current?.showModal?.()}
              />
              <CustomButton
                style={{
                  background: "var(--neutral-base)",
                  color: "#333",
                  marginLeft: 16,
                }}
                title="Cancel"
                onClick={() => refPopupEdit.current?.showModal?.()}
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "var(--primary-6)",
                  color: "#fff",
                  marginLeft: 16,
                  borderRadius: 8,
                  height: 35,
                  width: 70,
                  fontWeight: "600",
                }}
              >
                Save
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <CustomButton
                style={{
                  background: "var(--error-1-bg)",
                  color: "var(--error-2-default)",
                }}
                title="Cancel"
                onClick={() => history.push("/category")}
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: "var(--primary-6)",
                  color: "#fff",
                  marginLeft: 16,
                  height: 35,
                  width: 70,
                  fontWeight: "600",
                }}
              >
                Save
              </Button>
            </div>
          )}
          <div className="menu_new">
            <p className="menu_new_name">Name</p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the menu name",
                },
              ]}
            >
              <Input placeholder="Category name..." />
            </Form.Item>

            <p className="menu_new_name">Status</p>
            <Form.Item
              className="custom-form-item"
              name="is_active"
              valuePropName="checked"
            >
              <Switch
                checked={isToggled}
                onChange={handleToggle}
                style={{
                  backgroundColor: isToggled ? "var(--primary-6)" : "#d9d9d9",
                }}
                size="default"
              />
            </Form.Item>

            <p className="menu_new_name">Description (optional)</p>
            <Form.Item name="description">
              <Input placeholder="Description of category..." />
            </Form.Item>

            <p className="menu_new_name">Menu</p>
            <Form.Item
              className="custom-form-item"
              name="menu_ids"
              rules={[
                {
                  required: true,
                  message: "Please select at least 1 option",
                },
              ]}
            >
              <Checkbox.Group>
                {menuList?.map?.((menu) => (
                  <Checkbox
                    className="custom-checkbox"
                    key={menu?.entity_id}
                    value={menu?.entity_id}
                  >
                    {menu?.name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>

            <p className="menu_new_name">Kitchen Station</p>
            <Form.Item
              name="kitchen_station"
              rules={[
                {
                  required: true,
                  message: "Please select an kitchen station",
                },
              ]}
            >
              <Select
                className="custom-select"
                style={{ border: "none", boxShadow: "none" }}
              >
                {stations?.map?.((m) => (
                  <Option key={m?.printer_id} value={m?.printer_id}>
                    {m?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>

        <PopupAction
          title="Delete this category?"
          content={"Once deleted, it cannot be undone"}
          onConfirm={deleteCategory}
          onCancel={() => {}}
          ref={refPopupDelete}
        />
        <PopupAction
          title="Cancel editing?"
          content={"Your changes won't be saved"}
          onConfirm={() => {
            history.push("/category");
          }}
          onCancel={() => {}}
          ref={refPopupEdit}
          titleActionLeft="Keep editing"
          titleActionRight="Confirm cancel"
        />
      </div>
    </>
  );
};

export default Index;
