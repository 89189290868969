import React from "react";
import Header from "components/Header";
import MenuBar from "components/MenuBar";
import SearchInput from "components/SearchInput";
import CustomButton from "components/CustomButton";
import icon_plus from "assets/icon/icon_plus.svg";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { apiGetMenu } from "apis/Menu";
import { debounce } from "lodash";
import { AlertContext } from "handlers/alertContext";
import { useContext } from "react";
import "./index.scss";
import { columnsMenu } from "./columns";

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [menuList, setMenuList] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [search, setSearch] = React.useState();
  const { openModal } = useContext(AlertContext);

  const windowHeight = window.innerHeight;

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setCurrentPage(pagination.current);
    getMenus({
      search,
      page: pagination.current,
      field,
      position: order == "descend" ? "DESC" : "ASC",
    });
  };

  const getMenus = ({ search, page, field, position }) => {
    setLoading(true);
    apiGetMenu({
      search,
      currentPage: page,
      field: field ?? "entity_id",
      position: position ?? "ASC",
      pageSize: 10,
    }).then((res) => {
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      setMenuList(res?.data?.merchantMenus?.items ?? []);
      setTotalPage(res?.data?.merchantMenus?.page_info?.total_pages);
      setLoading(false);
    });
  };
  const searchData = () => {
    getMenus({ search, page: 1 });
    setCurrentPage(1);
  };
  const delayedSearchData = debounce(() => searchData(), 350);

  React.useEffect(() => {
    delayedSearchData();
  }, [search]);

  return (
    <>
      <Header />
      <div className="container-box body_history">
        <MenuBar title="Menu Management / Menus" />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            placeholder="Breakfast menu ..."
          />
          <CustomButton
            style={{ marginLeft: 16 }}
            leftIcon={icon_plus}
            title="New Menu"
            onClick={() => history.push("new_menu")}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Table
            loading={isLoading}
            rowKey="order_number"
            columns={columnsMenu}
            dataSource={menuList}
            className="table-menu"
            rowClassName={"row-table-menu"}
            onRow={(record, index) => ({
              onClick: () => {
                history.push(`edit_menu/${record?.entity_id}`);
              },
            })}
            scroll={{ y: windowHeight - 300 }}
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: totalPage * 10,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
