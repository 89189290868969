import React, { useState, useEffect } from "react";
import {Modal, Button, Select, Radio, Spin, Row, Input} from "antd";
import "./index.scss";
import { CaretDownOutlined, CloseCircleFilled } from "@ant-design/icons";
import locationIcon from "../../assets/placeholder.png";
import homeIcon from "../../assets/shopping-basket.png";
import backIcon from "../../assets/back-button.png";
import {
  apiMoveOrderToAnother,
} from "../../apis/Order";
import { customNotification, getLocalStorage } from "utils/CommomFunction";
const { info } = Modal;
const { Option } = Select;

export const OrderTransferModal = (props) => {
  const { showModalTransfer, closeModalTransfer, dataOrder, handleClose, openCurrentModalOpen } = props;
  const [storeList, setStoreList] = useState();
  const [storeInArea, setStoreInArea] = useState();
  const [selectStore, setSelectStore] = useState(null);
  const [reason, setReason] = useState();
  const [note, setNote] = useState();
  const [loadingButton, setLoadingButton] = useState(false);

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(2);
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    if (showModalTransfer) {
      setReason(null);
      setNote(null);
    }
  }, [showModalTransfer])


  useEffect(() => {
    // apiGetAllStoreInTheArea().then((res) => {
    //   if (!res.error && res.data) {
    //     axios(res.data.getStoreJsonData.url).then((res) => {
    //       setStoreList(Object.values(res.data));
    //     });
    //   }
    // });
  }, []);
  useEffect(() => {
    if (storeList) {
      const store = storeList.find(
        (item) => item?.name === getLocalStorage("restaurant")
      );
      const storeInArea = storeList
        .filter(
          (item) => item?.id !== store?.id && item?.city?.id === store?.city?.id
        )
        .map((item) => ({
          ...item,
          distance: getDistanceFromLatLonInKm(
            store?.latitude,
            store?.longitude,
            item?.latitude,
            item?.longitude
          ),
        }))
        .sort((a, b) => a?.distance - b?.distance);
      setStoreInArea(storeInArea);
    }
  }, [storeList]);

  const handleConfirmTransfer = () => {
    if (!selectStore) {
      return info({
        icon: <></>,
        content: <span>Vui lòng chọn cửa hàng</span>,
        onOk() {
        },
        onCancel() {
        },
      });
    }
    if (!reason) {
      return info({
        icon: <></>,
        content: <span>Vui lòng chọn lý do</span>,
        onOk() {
        },
        onCancel() {
        },
      });
    }
    setLoadingButton(true);
    let message = reason;
    if (reason === 'Lý do khác') {
      message = note;
    }
    apiMoveOrderToAnother({
      orderId: dataOrder.id,
      restaurantId: selectStore,
      reason: message,
    }).then((res) => {
      setLoadingButton(false);
      if (res.data && !res.error) {
        customNotification("success", "Đơn hàng đã được chuyển thành công");
          closeModalTransfer();
          handleClose();
        // window.location.reload();
      }
    });
  };
  const title = (
    <div style={{ borderBottom: "0.5px solid #d3cbcb", paddingBottom: 20 }}>
      <h1>Chuyển đơn hàng #{dataOrder?.order_number}</h1>
      <h2>Vui lòng lựa chọn cửa hàng bạn muốn chuyển đơn hàng này đến.</h2>
    </div>
  );

  const merchant = (merchantData) => {
    return (
      <div className="modal-merchant-flex" onClick={() => {
          setSelectStore(merchantData?.id);
      }}>
        <div className="modal-merchant-left">
          <img src={locationIcon} style={{ width: "20px", height: "20px" }} />
          <div style={{ fontSize: 12, marginTop: 3 }}>
            {merchantData?.distance} km
          </div>
        </div>
        <div className="modal-merchant-mid">
          <div style={{ fontWeight: "bold", font: 15 }}>
            {merchantData?.name}
          </div>
          <div style={{ display: "flex" }}>
            <img
              src={homeIcon}
              style={{
                width: "15px",
                height: "15px",
                marginTop: "3px",
                marginRight: "5px",
              }}
            />
            <div style={{ font: 14 }}>{merchantData?.address}</div>
          </div>
        </div>
        <div className="modal-merchant-right">
          <span>
            <Radio checked={selectStore === merchantData?.id} />
          </span>
        </div>
      </div>
    );
  };
  const footer = (
    <div className="modal-button-container">
      <div className="modal-button-container"></div>
      <Button
        className="modal-button-confirm"
        onClick={handleConfirmTransfer}
        loading={loadingButton}
      >
        {!loadingButton && (
          <div className="modal-text-confirm">XÁC NHẬN CHUYỂN ĐƠN</div>
        )}
      </Button>
      <div className="modal-button-container"></div>
      <Button className="modal-button-back" onClick={() => {
        closeModalTransfer();
        openCurrentModalOpen();
      }}>
        <div className="modal-text-back">
          <img
            src={backIcon}
            style={{ width: "16px", height: "16px", marginRight: 5 }}
          ></img>
          <div style={{ fontWeight: "bold" }}>QUAY LẠI</div>
        </div>
      </Button>
    </div>
  );
  const closeIcon = (
    <span>
      <CloseCircleFilled
        style={{
          color: "#e31837",
          marginLeft: "70px",
          fontSize: 46,
          backgroundColor: "#ffffff",
          borderRadius: 50,
        }}
      />
    </span>
  );
  return (
    <>
      <Modal
          centered={true}
          destroyOnClose={true}
          footer={footer}
        className="modal-container"
        title={title}
        open={showModalTransfer}
        onCancel={closeModalTransfer}
        closeIcon={closeIcon}
      >
        <Select
          style={{ width: "100%", borderRadius: "10px" }}
          size="large"
          showSearch
          placeholder="Chọn lý do chuyển đơn hàng"
          optionFilterProp="children"
          className="modal-select"
          suffixIcon={<CaretDownOutlined />}
          value={reason}
          onSelect={(value) => setReason(value)}
        >
          <Option value="Quá tải">Quá tải</Option>
          <Option value="Hết món">Hết món</Option>
          <Option value="Lý do khác">Lý do khác</Option>
        </Select>
        {reason === 'Lý do khác' && (
            <Input.TextArea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                rows={4}
                placeholder="Vui lòng nhập lý do"
                style={{ marginTop: '10px', borderRadius: 0, padding: 10 }}
            />

        )}
        <div style={{ height: "320px", overflow: "scroll" }}>
          {storeInArea ? (
            <>
              {storeInArea?.map((item, index) => {
                return <div key={index}>{merchant(item)}</div>;
              })}
            </>
          ) : (
            <Row justify="center" style={{ marginTop: 30 }}>
              <Spin spinning></Spin>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
};
