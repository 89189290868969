import { Modal } from "antd";
import React from "react";
import ButtonSubmit from "../Button/ButtonSubmit";
import iconReadyShip from "../../assets/ready_ship.png";
import { useTranslation } from "react-i18next";

export default function ModalCookingPickup({
  renderTitleModal,
  isShowModalCookingPickUp,
  setIsShowModalCookingPickUp,
  handleSubmitOrderReadyToPickUp,
  loadingButton,
  renderModalData,
  detailOrder,
  dataOrderModal,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      className="modal-recieved"
      title={renderTitleModal}
      open={isShowModalCookingPickUp}
      width={900}
      onCancel={() => setIsShowModalCookingPickUp(false)}
      footer={[
        <span
          key={dataOrderModal?.id}
          onClick={() => handleSubmitOrderReadyToPickUp(dataOrderModal?.id)}
        >
          <ButtonSubmit
            loadingButton={loadingButton}
            title={t("readyToShip")}
            key={3}
            image={iconReadyShip}
            color="#3FB4C3"
            classname="btn-cooking"
          />
          ,
        </span>,
        // <ButtonGr key={2} />,
      ]}
    >
      {renderModalData(detailOrder, dataOrderModal)}
    </Modal>
  );
}
