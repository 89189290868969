import { Row } from "antd";
import { useState } from "react";

const RadioOption = ({ name = "", checked, onChecked }) => {
  return (
    <Row
      align={"middle"}
      style={{ cursor: "pointer", gap: 16, marginTop: 16 }}
      onClick={onChecked}
    >
      {checked ? <IconCheck /> : <IconUnCheck />} {name}
    </Row>
  );
};

export default RadioOption;

const IconUnCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.836 16.002C28.836 23.0898 23.0892 28.8367 16 28.8367C8.91078 28.8367 3.164 23.0898 3.164 16.002C3.164 8.91283 8.91081 3.16602 16 3.16602C23.0892 3.16602 28.836 8.91283 28.836 16.002ZM11.4228 26.9884C12.8794 27.5722 14.4375 27.8606 16.0065 27.8366C19.1227 27.8025 22.0998 26.5422 24.2916 24.3265C26.4836 22.1106 27.7131 19.1196 27.7131 16.0027C27.7131 12.8858 26.4836 9.89475 24.2916 7.67887C22.0999 5.46327 19.1228 4.20149 16.0066 4.16739C14.4375 4.14347 12.8794 4.4318 11.4228 5.0156C9.96585 5.59954 8.63961 6.46743 7.52125 7.56876C6.4029 8.6701 5.51477 9.98287 4.90855 11.4307C4.30233 12.8785 3.99014 14.4324 3.99014 16.002C3.99014 17.5716 4.30233 19.1255 4.90855 20.5734C5.51477 22.0212 6.4029 23.3339 7.52125 24.4353C8.63961 25.5366 9.96585 26.4045 11.4228 26.9884Z"
        fill="#4A505C"
        stroke="#4A505C"
      />
    </svg>
  );
};

const IconCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 28.8327C8.91216 28.8327 3.16669 23.0872 3.16669 15.9993C3.16669 8.91149 8.91216 3.16602 16 3.16602C23.0879 3.16602 28.8334 8.91149 28.8334 15.9993C28.8334 23.0872 23.0879 28.8327 16 28.8327ZM16 27.166C18.9616 27.166 21.8019 25.9895 23.896 23.8954C25.9902 21.8012 27.1667 18.9609 27.1667 15.9993C27.1667 13.0378 25.9902 10.1975 23.896 8.10332C21.8019 6.00917 18.9616 4.83268 16 4.83268C13.0384 4.83268 10.1982 6.00917 8.10399 8.10332C6.00984 10.1975 4.83335 13.0378 4.83335 15.9993C4.83335 18.9609 6.00984 21.8012 8.10399 23.8954C10.1982 25.9895 13.0384 27.166 16 27.166ZM16 22.166C14.3645 22.166 12.796 21.5163 11.6395 20.3598C10.4831 19.2034 9.83335 17.6349 9.83335 15.9993C9.83335 14.3638 10.4831 12.7953 11.6395 11.6389C12.796 10.4824 14.3645 9.83268 16 9.83268C17.6355 9.83268 19.204 10.4824 20.3605 11.6389C21.517 12.7953 22.1667 14.3638 22.1667 15.9993C22.1667 17.6349 21.517 19.2034 20.3605 20.3598C19.204 21.5163 17.6355 22.166 16 22.166Z"
        fill="#389E0D"
        stroke="#389E0D"
      />
    </svg>
  );
};
