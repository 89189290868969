import React from "react";
import { Modal } from "antd";
import jollibee_avatar from "assets/jollibee_avatar.png";

const PopupWarningFirebase = React.forwardRef(
    (
        {
        },
        ref,
    ) => {

        const [open, setOpen] = React.useState(false);

        React.useImperativeHandle(ref, () => ({
            show: () => {
                setOpen(true);
            },
            hide: () => {
                if (open) {
                    setOpen(false)
                }
            },
        }));

        const renderContent = () => {
            return (
                <div className="wifi_container">
                    <img src={jollibee_avatar} style={{ width: 200, height: 200 }} />
                    <h3 className="wifi_title">
                        Firebase token not found
                    </h3>
                    {/* <div className="wifi_content">
                        Không có firebase token
                    </div> */}
                    <div className="wifi_content" style={{ marginBottom: 10, color: "#404040" }}>
                        {`Hiện tại không lấy được firebase token ( là già trị để nhận thông báo khi có đơn ) nên không thể đăng nhập.
                    vui lòng kiểm tra lại: cài đặt thông báo của trình duyệt, đường truyền mạng của bạn.
                        Sau đó hãy đóng trình duyệt và bật lại nhé.`}
                    </div>
                </div>
            );
        };

        return (
            <Modal
                centered={true}
                destroyOnClose={true}
                className="modal-container modal-logout"
                title={<></>}
                open={open}
                footer={null}
                closable={false}
            >
                <div style={{ fontWeight: "bold", fontSize: 25 }}>
                    {renderContent()}
                </div>
            </Modal>
        );
    },
);

export default PopupWarningFirebase;