import { callApi } from "apis";

export const apiMerchantReportByPaymentMethods = (variables) =>
  callApi(
    `query {
        merchantReportByPaymentMethods {
            items {
                credit_card {
                    value
                    currency
                }
                cash {
                    value
                    currency
                }
                discount {
                    value
                    currency
                }
            }
            total_amount {
                value
                currency
            }
        }
    }`,
    variables
  );

export const apiMerchantGetBatchInvoices = (variables) =>
  callApi(
    `query($currentPage: Int, $pageSize: Int) { 
        merchantGetBatchInvoices(currentPage: $currentPage, pageSize: $pageSize) {
            last_settle_date
            total_count
            total_amount {
                amount
                currency
            }
            items {
            invoice_number
            state
            total {
                tip_amount {
                value
                currency
                }
                subtotal {
                value
                currency
                }
                discounts {
                label
                amount {
                    value
                    currency
                }
                }
                total_tax {
                value
                currency
                }
                taxes {
                amount {
                    value
                    currency
                }
                title
                rate
                }
                grand_total {
                value
                currency
                }
                base_grand_total {
                value
                currency
                }
                total_shipping {
                value
                currency
                }
            }
            }
            page_info {
            page_size
            current_page
            total_pages
            }
        }
    }`,
    variables
  );

export const apiMerchantSettle = () =>
  callApi(
    `mutation {
            posSettleMerchant
        }`
  );
