import React, { useEffect, useState, useRef } from "react";
import Header from "components/Header";
import { Input, Table, Modal, Tag, Row, Col, Form, Select, Switch } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./index.scss";
import arrowBack from "../../assets/arrow.svg";
import shipperIcon from "../../assets/shipping_icon.svg";
// import { firebaseMessaging } from "firebaseInit";
import { useHistory } from "react-router-dom";
import { customNotification } from "utils/CommomFunction/index";
import Notification from "components/Notification";
import { apiGetListOrder } from "apis/Order";
import {
  apiGetShipperList,
  enableShipper,
  merchantAcceptShipping,
  apiCreateShipper,
  apiUpdateShipper,
} from "apis/Shipper";
import { apiGetNotificationList } from "apis/Notification";
import { renderFooterShipper } from "./render";
import axios from "axios";
import { CustomInput } from "components";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;
const { Option } = Select;
const ShipperPage = () => {
  const [shipperList, setShipperList] = useState([]);
  const [shipperListFilter, setShipperListFilter] = useState([]);
  const [shipperDetail, setShipperDetail] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [dataOrderModal, setDataOrderModal] = useState();
  const [storeList, setStoreList] = useState();
  const [orderList, setOrderList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [shipperName, setShipperName] = useState();
  const history = useHistory();
  const [reload, set_reload] = useState(false);
  const loopPlayAudio = useRef();
  const playAudio = useRef();
  const [form] = Form.useForm();
  var blurred = false;

  const windowHeight = window.innerHeight;

  const renderStatus = (
    shipperDetail,
    style = { padding: "5px 25px", borderRadius: "5px", fontWeight: "bold" }
  ) => {
    let color = "#0A8D87",
      content = "ACTIVE";
    switch (shipperDetail?.status) {
      case "INACTIVE":
        color = "#E31837";
        content = "INACTIVE";
        break;
    }
    return (
      <Tag color={color} style={style}>
        {content}
      </Tag>
    );
  };
  const { t } = useTranslation();
  const columns = [
    {
      title: t("shipper_id"),
      dataIndex: "shipper_id",
      width: 150,
      render: (shipper_id) => <div>{`# ${shipper_id}`}</div>,
    },
    {
      title: t("lastname"),
      dataIndex: "lastname",
      align: "center",
      width: 150,
      render: (time, record) => (
        <div>
          {record?.lastname} {record?.firstname}
        </div>
      ),
    },
    {
      title: t("phone"),
      dataIndex: "phone",
      align: "center",
      width: 200,
      render: (phone) => <div style={{ textAlign: "center" }}>{phone}</div>,
    },
    {
      title: t("orders"),
      dataIndex: "orders",
      align: "left",
      width: 50,
      render: (orders) => {
        let order = orderList?.find(
          (order) => order?.order_number === orders?.[0]?.order_number
        );
        if (order) {
          return <div>{`${order?.order_number}`}</div>;
        }
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (status, record) => renderStatus(record),
    },
    {
      title: "",
      dataIndex: "action",
      align: "right",
      width: 50,
      render: (status, record) => {
        return (
          <div
            key={Math.random()}
            className="view-more"
            onClick={() => {
              let shipper = shipperList.find(
                (shipper) => shipper?.shipper_id === record?.shipper_id
              );
              setShipperDetail(shipper);
              setDataOrderModal(record);
              setIsShowModal(true);
            }}
          >
            {t("edit")}
          </div>
        );
      },
    },
  ];

  const handleAcceptShipping = (id, type) => {
    //set type
    type = type === 1 ? 0 : 1;
    merchantAcceptShipping({ id, type }).then((res) => {
      if (res.data && !res.error) {
        setIsLoading(true);
        apiGetShipperList().then((res) => {
          if (res.data && !res.error) {
            setIsLoading(false);
            setIsShowModal(false);
            setShipperList(res?.data?.shipperList?.shipper);
            setShipperListFilter(res?.data?.shipperList?.shipper);
          }
        });
      }
    });
  };

  const renderModalForm = () => {
    if (shipperDetail?.lastname?.length > 0) {
      form.setFieldsValue({
        firstname: shipperDetail?.firstname,
        lastname: shipperDetail?.lastname,
        userName: shipperDetail?.username,
        phone: shipperDetail?.phone,
        gender: parseInt(shipperDetail?.gender || 3),
        accept_order: shipperDetail?.accept_order,
      });
    }
    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitForm}
        autoComplete="off"
        className="modal-form"
        id="shipper-form"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="firstname"
              label={t("firstname")}
              rules={[{ required: true, message: "Vui lòng nhập tên" }]}
            >
              <Input placeholder={t("firstname")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastname"
              label={t("last_name")}
              rules={[{ required: true, message: "Vui lòng nhập họ" }]}
            >
              <Input placeholder={t("last_name")} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="gender"
              label={t("gender")}
              rules={[{ required: true, message: "Vui lòng chọn giới tính" }]}
            >
              <Select placeholder={t("gender")}>
                <Option value={1}>{t("male")}</Option>
                <Option value={2}>{t("female")}</Option>
                <Option value={3}>{t("other")}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="phone"
              label={t("phoneNumber")}
              rules={[
                { required: true, message: "Vui lòng nhập số điện thoại" },
              ]}
            >
              <Input placeholder={t("phoneNumber")} />
            </Form.Item>
          </Col>
        </Row>
        {/* {shipperDetail?.lastname?.length > 0 && (
          <Row>
            <Col span={24}  >
              <Form.Item name="status" label={t('')}
                <Select placeholder="Chọn cửa hàng">
                  {storeList?.map?.((store, index) => <Option value={store.id} key={index}>{store.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )} */}
        <Row>
          <Col span={24}>
            <Form.Item
              name="password"
              label={t("password")}
              rules={
                !shipperDetail
                  ? [
                      {
                        required: true,
                        message: "Vui lòng nhập mật khẩu",
                      },
                      {
                        message:
                          "Mật khẩu phải có ít nhất 7 kí tự gồm số và chữ hoặc số, chữ và kí tự đặc biệt",
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]){0,}[A-Za-z\d@$!%*?&]{8,}$/,
                      },
                    ]
                  : [
                      {
                        message:
                          "Mật khẩu phải có ít nhất 7 kí tự gồm số và chữ hoặc số, chữ và kí tự đặc biệt",
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]){0,}[A-Za-z\d@$!%*?&]{8,}$/,
                      },
                    ]
              }
            >
              <Input.Password
                placeholder={t("password")}
                type="password"
                autocomplete="new-password"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="rePassword"
              label={t("confirmPassword")}
              dependencies={["password"]}
              hasFeedback
              rules={
                !shipperDetail
                  ? [
                      {
                        required: true,
                        message: "Vui lòng nhập xác nhận mật khẩu",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Mật khẩu xác nhận không khớp !"
                          );
                        },
                      }),
                    ]
                  : [
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !isEmpty(getFieldValue("password")) &&
                            isEmpty(value)
                          ) {
                            return Promise.reject(
                              "Vui lòng nhập xác nhận mật khẩu"
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Mật khẩu xác nhận không khớp !"
                          );
                        },
                      }),
                    ]
              }
            >
              <Input.Password placeholder={t("confirmPassword")} />
            </Form.Item>
          </Col>
        </Row>
        {/* {shipperDetail?.lastname?.length > 0 && (
          <Row>
            <Col span={24} offset={14}>
              <Form.Item>
                <span style={{ marginRight: 20, fontWeight: "bold" }}>
                  {" "}
                  Tắt/Bật trạng thái nhận đơn hàng{" "}
                </span>{" "}
                <Switch defaultChecked={shipperDetail?.accept_order === 1} onChange={() => {
                  handleAcceptShipping(shipperDetail?.id, shipperDetail?.accept_order);
                }} />
              </Form.Item>
            </Col>
          </Row>
        )} */}
      </Form>
    );
  };

  const renderTitleModal = () => {
    let title = t("addMoreShipper");
    let style = { paddingTop: "0.5px", fontWeight: "bold" };
    if (shipperDetail?.lastname?.length > 0) {
      title = `${shipperDetail?.lastname} ${shipperDetail?.firstname}`;
    }
    return (
      <div
        key={
          shipperDetail?.shipper_id ? shipperDetail?.shipper_id : Math.random()
        }
      >
        <h3 className="title-left">
          {title}{" "}
          {shipperDetail?.lastname?.length > 0 &&
            renderStatus(shipperDetail, style)}
        </h3>
        {shipperDetail?.lastname?.length > 0 && (
          <p style={{ fontWeight: "200", marginTop: "10px" }}>
            {t("shipper_id")}: {shipperDetail?.shipper_id}
          </p>
        )}
      </div>
    );
  };

  const handleOkAccountStatus = (shipperId, status) => {
    enableShipper({ shipperId, status: convertStatus[status] }).then((res) => {
      if (!res.errors && res.data) {
        setIsShowModal(false);
        setShipperDetail();
        apiGetShipperList().then((res) => {
          if (!res.errors && res.data) {
            setShipperList(res?.data?.shipperList?.shipper);
            setShipperListFilter(res?.data?.shipperList?.shipper);
          }
        });
      }
    });
  };

  const handleSubmitForm = () => {
    form
      .validateFields()
      .then((values) => {
        if (!shipperDetail) {
          if (!values.password.includes(values.rePassword)) {
            throw Error;
          }
        }
        if (values.status === undefined) {
          delete values.status;
        }
        if (values?.rePassword) {
          delete values?.rePassword;
        }
        if (shipperDetail) {
          updateShipperInfo(values);
          return;
        } else {
          try {
            setIsLoading(true);
            apiCreateShipper({
              ...values,
              username: `${values.firstname} ${values.lastname}`,
            }).then((res) => {
              callApiGetShipperList();
              setIsLoading(false);
              setIsShowModal(false);
              form.resetFields();
            });
          } catch (error) {
            setIsLoading(false);
            setIsShowModal(false);
            form.resetFields();
          }
        }
      })
      .catch((info) => {});
  };

  const convertStatus = {
    INACTIVE: 0,
    ACTIVE: 1,
  };

  const updateShipperInfo = (values) => {
    try {
      const status = shipperDetail?.status;
      let data = {
        ...values,
        shipper_id: shipperDetail?.shipper_id,
        status: convertStatus[status]?.toString?.(),
      };

      if (data?.userName) {
        delete data?.userName;
      }

      setIsLoading(true);
      apiUpdateShipper(data).then((res) => {
        callApiGetShipperList();
        setIsLoading(false);
        setIsShowModal(false);
        form.resetFields();
      });
    } catch (error) {
      setIsLoading(false);
      setIsShowModal(false);
      form.resetFields();
    }
  };

  const callApiGetShipperList = () => {
    apiGetShipperList().then((res) => {
      setShipperList(res?.data?.shipperList?.shipper);
      setShipperListFilter(res?.data?.shipperList?.shipper);
    });
  };

  const handleChangeAccountStatus = (shipperDetail) => {
    let content = "kích hoạt";
    let newStatus = "ACTIVE";
    switch (shipperDetail?.status) {
      case "ACTIVE":
        content = "vô hiệu";
        newStatus = "INACTIVE";
        break;
    }
    return confirm({
      className: "account-confirm-modal",
      icon: <></>,
      content: (
        <div className="confirm-account">
          Có phải bạn muốn {content} tài khoản này?
        </div>
      ),
      footer: <></>,
      onOk: () => handleOkAccountStatus(shipperDetail?.shipper_id, newStatus),
      okText: "XÁC NHẬN",
      cancelText: (
        <>
          <CloseCircleOutlined
            style={{ color: "#e31837", fontSize: "16px" }}
            size={4}
          />{" "}
          HỦY
        </>
      ),
    });
  };

  const handleSearch = (e) => {
    let newShipperList = [...shipperList];
    newShipperList = newShipperList.filter(
      (shipper) =>
        shipper.lastname.toLowerCase().includes(e.target.value.toLowerCase()) ||
        shipper.firstname
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        shipper.username.includes(e.target.value)
    );
    setShipperName(e.target.value);
    setShipperListFilter(newShipperList);
  };

  useEffect(() => {
    // firebaseMessaging.onMessage((payload) => {
    //   setReload();
    //   set_reload(!reload);
    //   apiGetNotificationList({ type: "merchant" }).then((res) => {
    //     if (
    //       res.data &&
    //       !res.errors &&
    //       res.data.getMerchantNotificationList.items.length &&
    //       res.data.getMerchantNotificationList.items[0].is_read === 0
    //     ) {
    //       customNotification(
    //         "success",
    //         <Notification
    //           title={res.data.notifications.list[0].title}
    //           content={res.data.notifications.list[0].content}
    //         />,
    //         "notification-custom"
    //       );
    //     }
    //   });
    // });
    setIsLoading(true);
    apiGetListOrder().then((res) => {
      setIsLoading(false);
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrders?.orders);
      }
    });
    apiGetShipperList().then((res) => {
      if (!res.errors && res.data) {
        setShipperList(res?.data?.shipperList?.shipper || []);
        setShipperListFilter(res?.data?.shipperList?.shipper || []);
      }
    });
    // return () => {
    //   clearInterval(loopPlayAudio.current);
    //   clearTimeout(playAudio.current);
    // };
  }, []);

  window.onblur = function () {
    blurred = true;
  };
  window.onfocus = function () {
    blurred && setReload();
  };

  const setReload = () => {};
  return (
    <div className="shipper-page">
      <Header reload={reload} />
      <div className="container-box">
        <div className="header-bottom">
          <h3 className="header-bottom-left">
            <img src={shipperIcon} alt="icon" />
            SHIPPER
          </h3>
          <div
            className="header-bottom-right"
            style={{ justifyContent: "flex-end" }}
          >
            <div className="search-bar" style={{ width: "50%" }}>
              <CustomInput
                placeholder={t("search")}
                value={shipperName}
                onChange={handleSearch}
              />
            </div>

            <button className="back-btn" onClick={() => history.push("/home")}>
              {" "}
              <img src={arrowBack} style={{ marginRight: 10 }} />
              {t("goback")}
            </button>
            <button
              className="back-btn"
              onClick={() => {
                setIsShowModal(true);
                setShipperDetail(null);
              }}
              style={{ backgroundColor: "#E31837" }}
            >
              {" "}
              <PlusCircleOutlined
                style={{ fontSize: "19px", marginRight: "10px" }}
              />
              {t("addMoreShipper")}
            </button>
          </div>
        </div>
        <Table
          onRow={(record, index) => ({
            onClick: () => {
              let shipper = shipperList.find(
                (shipper) => shipper.shipper_id === record.shipper_id
              );
              setShipperDetail(shipper);
              setDataOrderModal(record);
              setIsShowModal(true);
            },
          })}
          loading={isLoading}
          rowKey="order_number"
          columns={columns}
          dataSource={shipperListFilter}
          className="table-refund"
          pagination={true}
          scroll={{ y: windowHeight - 300 }}
        />
      </div>
      <Modal
        bodyStyle={{
          height: shipperDetail?.lastname?.length > 0 ? "550px" : "none",
        }}
        centered={true}
        onOk={form.submit}
        destroyOnClose={true}
        className="modal-shipper"
        open={isShowModal}
        onCancel={() => {
          setIsShowModal(false);
          setShipperDetail({});
          form.resetFields();
        }}
        title={renderTitleModal()}
        width={695}
        footer={renderFooterShipper(
          shipperDetail,
          isLoading,
          handleChangeAccountStatus,
          handleSubmitForm
        )}
      >
        {renderModalForm()}
      </Modal>
    </div>
  );
};

export default ShipperPage;
