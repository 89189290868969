import React from "react";

export default function StoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="34"
      viewBox="0 0 36 34"
      fill="none"
    >
      <path
        d="M3 15.3115V22.8215C3 27.5399 3 29.8999 4.465 31.3665C5.92833 32.8332 8.28667 32.8332 13 32.8332H23C27.7133 32.8332 30.0717 32.8332 31.535 31.3665C32.9983 29.8999 33 27.5399 33 22.8199V15.3115"
        stroke="var(--text-primary)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9999 25.2953C21.8599 26.3069 20.0449 26.9619 17.9999 26.9619C15.9549 26.9619 14.1399 26.3069 12.9999 25.2953M27.6599 1.17192L8.24991 1.22026C5.35324 1.07026 4.60991 3.30359 4.60991 4.39692C4.60991 5.37359 4.48491 6.79692 2.70991 9.47192C0.933242 12.1469 1.06658 12.9419 2.06824 14.7953C2.89824 16.3319 5.01158 16.9319 6.11491 17.0336C9.61491 17.1136 11.3182 14.0869 11.3182 11.9586C13.0549 17.3036 17.9932 17.3036 20.1932 16.6919C22.3966 16.0803 24.2866 13.8886 24.7316 11.9586C24.9916 14.3586 25.7816 15.7569 28.1099 16.7186C30.5249 17.7136 32.5999 16.1919 33.6416 15.2153C34.6832 14.2403 35.3516 12.0736 33.4949 9.69192C32.2149 8.05026 31.6799 6.50359 31.5049 4.90026C31.4049 3.97026 31.3149 2.97192 30.6616 2.33692C29.7082 1.40859 28.3382 1.12859 27.6599 1.17192Z"
        stroke="var(--text-primary)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
