import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { MyRoutes } from "./routes";

import NetworkProvider from "./components/NetworkProvider";
import "./ReactotronConfig";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ThemeProvider } from "handlers/ThemeContext";
import { PrinterProvider } from "handlers/printerContext";
import { AlerProvider } from "handlers/alertContext";
const App = (props) => {
  return (
    <NetworkProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider>
            <PrinterProvider>
              <AlerProvider>
                <MyRoutes />
              </AlerProvider>
            </PrinterProvider>
          </ThemeProvider>
        </I18nextProvider>
      </BrowserRouter>
    </NetworkProvider>
  );
};

export default App;
