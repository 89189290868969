import dayjs from "dayjs";
export const columns = [
  {
    title: "Batch ID",
    dataIndex: "id",
    key: "id",
    render: (entity_id) => {
      return (
        <div style={{ color: "var(--text-primary)", fontSize: 16 }}>
          #{entity_id}
        </div>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (created) => {
      return (
        <div style={{ fontSize: 16 }}>
          {dayjs(created).format("DD/MM/YYYY")}
        </div>
      );
    },
    sorter: true,
  },
  {
    title: "Time",
    dataIndex: "created_at",
    key: "created_at",
    render: (created) => {
      return (
        <div style={{ fontSize: 16 }}>{dayjs(created).format("hh:mm A")}</div>
      );
    },
    sorter: true,
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (total) => {
      return (
        <div
          style={{
            fontWeight: "600",
            fontSize: 17,
          }}
        >
          $ {total}
        </div>
      );
    },
  },
];
