import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { apiCompleteOrder } from "../../apis/Order";
import { customNotification } from "utils/CommomFunction";
const { Option } = Select;
const { info } = Modal;
function DeliveredModal(props) {
  const [loadingButton, setLoadingButton] = useState(false);
  const {
    isShowModalDelivered,
    closeModalDelivered,
    dataOrder,
    finalDelivered,
    onOpenWarningComplete,
  } = props;
  const initHour = () => {
    if (Number(moment().format("HH")) - 12 > 0) {
      if (Number(moment().format("HH")) - 12 < 10) {
        return `0${Number(moment().format("HH")) - 12}`;
      }
      return `${Number(moment().format("HH")) - 12}`;
    }
    return moment().format("HH");
  };
  const initMinute = moment().format("mm");
  const initStatus = Number(moment().format("HH")) - 12 >= 0 ? "PM" : "AM";
  const [form] = Form.useForm();
  const styleTimePicker = {
    width: "100%",
    height: "43px",
    border: "1px solid ##bcbcbc",
    borderRadius: "4px",
  };
  const onFinish = (values) => {
    const time = `${moment().format("YYYY-MM-DD ")} ${values.hourTime}:${
      values.minuteTime
    } ${values.status}`;

    if (
      moment(time, "YYYY-MM-DD  HH:mm A").unix() -
        moment(moment(dataOrder.created_at), "HH:mm A").unix() <
      0
    ) {
      return info({
        icon: <></>,
        content: <span>Thời gian giao hàng phải lớn hơn thời gian nhận</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setLoadingButton(true);
    apiCompleteOrder({
      id: dataOrder.id,
      time,
    }).then((res) => {
      setLoadingButton(false);
      if (!res?.data?.completeMerchant && res?.errors) {
        onOpenWarningComplete(res?.errors?.[0]?.message);
        return;
      }
      if (!res.errors && res.data) {
        customNotification(
          "success",
          `Your order ${dataOrder.order_number} have been successful`
        );
        finalDelivered();
      }
    });
  };
  const listHour = Array.apply(null, { length: 12 })
    .map(Number.call, Number)
    .map((item) => {
      if (item < 9) {
        return `0${item + 1}`;
      }
      return (item + 1).toString();
    });
  const listMinute = Array.apply(null, { length: 60 })
    .map(Number.call, Number)
    .map((item) => {
      if (item < 10) {
        return `0${item}`;
      }
      return item.toString();
    });
  const value = {
    status: initStatus,
    minuteTime: initMinute,
    hourTime: initHour(),
  };
  useEffect(() => {
    if (!!isShowModalDelivered) {
      form.setFieldsValue(value);
    }
  }, [isShowModalDelivered]);

  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      className="modal-delivered"
      title="Đã giao vào lúc"
      open={isShowModalDelivered}
      width={410}
      onCancel={closeModalDelivered}
      footer={[
        <Button
          loading={loadingButton}
          form="myForm"
          key="submit"
          htmlType="submit"
          className="btn-primary"
        >
          {!loadingButton && "LƯU"}
        </Button>,
      ]}
    >
      <Form
        id="myForm"
        name="basic"
        form={form}
        onFinish={onFinish}
        initialValues={value}
        layout="vertical"
      >
        <Form.Item>
          <Row align="middle" justify="space-between" span={24}>
            <Col span={9}>
              <Form.Item
                name="hourTime"
                label="Giờ"
                style={{ marginBottom: 0 }}
              >
                <Select style={styleTimePicker} placeholder="Chọn">
                  {listHour.map((item, index) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="minuteTime"
                label="Phút"
                style={{ marginBottom: 0 }}
              >
                <Select style={styleTimePicker} placeholder="Chọn">
                  {listMinute.map((item) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="status"
                label={"\u00A0"}
                style={{ marginBottom: 0 }}
              >
                <Select
                  suffixIcon={
                    <CaretDownOutlined style={{ color: "#1B1B1B" }} />
                  }
                >
                  <Option value="AM">AM</Option>
                  <Option value="PM">PM</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DeliveredModal;
