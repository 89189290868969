import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import SearchIcon from "./icons/search";
import { DarkInputDefault } from "components/atom/Input/input";
export default function InputSearch({
  onChangeText,
  allowClear,
  data,
  placeholder,
}) {
  return (
    <div
      style={{
        height: 40,
        width: "260px",
        paddingInline: 16,
        position: "relative",
      }}
    >
      <DarkInputDefault
        style={{
          background: "var(--neutral-base)",
          height: "100%",
          border: `1px solid var(--neutral-line)`,
          color: "var(--text-primary)",
          paddingInline: 16,
          paddingLeft: 40,
          borderRadius: 8,
        }}
        placeholder={placeholder ? placeholder : "Search "}
        onChange={(e) => onChangeText && onChangeText(e.target.value)}
        value={data}
      />

      <div
        style={{
          position: "absolute",
          left: 30,
          top: 10,
        }}
      >
        <SearchIcon />
      </div>
    </div>
  );
}
