import React from "react";

export default function MenuManagerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.3701 14.6484H18.5651C17.8751 14.6484 17.3151 14.0884 17.3151 13.3984C17.3151 12.7084 17.8751 12.1484 18.5651 12.1484H27.3701C28.0601 12.1484 28.6201 12.7084 28.6201 13.3984C28.6201 14.0884 28.0601 14.6484 27.3701 14.6484ZM27.3701 21.2484H18.5651C17.8751 21.2484 17.3151 20.6884 17.3151 19.9984C17.3151 19.3084 17.8751 18.7484 18.5651 18.7484H27.3701C28.0601 18.7484 28.6201 19.3084 28.6201 19.9984C28.6201 20.6884 28.0601 21.2484 27.3701 21.2484ZM27.3701 27.8484H18.5651C17.8751 27.8484 17.3151 27.2884 17.3151 26.5984C17.3151 25.9084 17.8751 25.3484 18.5651 25.3484H27.3701C28.0601 25.3484 28.6201 25.9084 28.6201 26.5984C28.6201 27.2884 28.0601 27.8484 27.3701 27.8484ZM13.1384 14.6484H12.6301C11.9401 14.6484 11.3801 14.0884 11.3801 13.3984C11.3801 12.7084 11.9401 12.1484 12.6301 12.1484H13.1384C13.8284 12.1484 14.3884 12.7084 14.3884 13.3984C14.3884 14.0884 13.8284 14.6484 13.1384 14.6484ZM13.1384 21.2484H12.6301C11.9401 21.2484 11.3801 20.6884 11.3801 19.9984C11.3801 19.3084 11.9401 18.7484 12.6301 18.7484H13.1384C13.8284 18.7484 14.3884 19.3084 14.3884 19.9984C14.3884 20.6884 13.8284 21.2484 13.1384 21.2484ZM13.1384 27.8484H12.6301C11.9401 27.8484 11.3801 27.2884 11.3801 26.5984C11.3801 25.9084 11.9401 25.3484 12.6301 25.3484H13.1384C13.8284 25.3484 14.3884 25.9084 14.3884 26.5984C14.3884 27.2884 13.8284 27.8484 13.1384 27.8484ZM27.0284 4.16675H12.9701C7.70508 4.16675 4.16675 7.86841 4.16675 13.3767V26.6234C4.16675 32.1317 7.70508 35.8334 12.9701 35.8334H27.0267C32.2934 35.8334 35.8334 32.1317 35.8334 26.6234V13.3767C35.8334 7.86841 32.2951 4.16675 27.0284 4.16675Z"
        fill="#1D2433"
      />
    </svg>
  );
}
