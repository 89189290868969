import _ from "lodash";

export function formatNumberWithCommas(number) {
  const formattedNumber = _.toNumber(number).toLocaleString("en-US");
  return formattedNumber;
}
export function roundToDecimals(num, decimals) {
  let factor = Math.pow(10, decimals);
  return Math.round(num * factor) / factor;
}
export const CURRENTCY = "USD";

export const getBase64 = (url) => {
  return fetch(url)
    .then((response) => {
      console.log({ url, response });
      return response.blob();
    })
    .then((blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    });
};
