export default function CustomerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
    >
      <path
        d="M12.5821 19.7629C19.2244 19.7629 24.831 20.8185 24.831 24.9006C24.831 28.9795 19.1885 30 12.5821 30L12.1309 29.9983C5.68797 29.9505 0.333252 28.8516 0.333252 24.8623C0.333252 20.7834 5.9758 19.7629 12.5821 19.7629ZM22.9494 17.3053C25.4755 17.2574 28.1924 17.5976 29.1954 17.8372C31.3219 18.2444 32.7195 19.0733 33.2984 20.2823C33.7893 21.2724 33.7893 22.4223 33.2984 23.4125C32.4129 25.2842 29.5558 25.8863 28.4452 26.0412C28.2153 26.0732 28.031 25.8815 28.0554 25.6547C28.6229 20.4675 24.1105 18.008 22.9429 17.4427C22.894 17.4155 22.8826 17.3772 22.8875 17.3517C22.8907 17.3357 22.9119 17.3101 22.9494 17.3053ZM12.5821 0C17.0815 0 20.6872 3.53108 20.6872 7.93735C20.6872 12.3436 17.0815 15.8747 12.5821 15.8747C8.08441 15.8747 4.4771 12.3436 4.4771 7.93735C4.4771 3.53108 8.08441 0 12.5821 0ZM23.3897 1.32571C27.7358 1.32571 31.1474 5.32793 29.9847 9.7853C29.2003 12.7894 26.361 14.7841 23.1973 14.7026C22.8793 14.6962 22.5678 14.6659 22.2645 14.6164C22.046 14.5781 21.9351 14.3369 22.0606 14.158C23.2674 12.4188 23.9556 10.3251 23.9556 8.08125C23.9556 5.73677 23.2055 3.5536 21.8992 1.76011C21.8568 1.70422 21.8258 1.61957 21.8682 1.55409C21.9008 1.50299 21.9661 1.47424 22.0264 1.46146C22.4667 1.37363 22.9184 1.32571 23.3897 1.32571Z"
        fill={"var(--text-primary)"}
      />
    </svg>
  );
}
