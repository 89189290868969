import { Table } from "antd";
import { BatchMenuBar } from "components/BatchMenuBar";
import Header from "components/Header";
import React from "react";
import { columns } from "./column";
export default function BatchHistory() {
  const [isLoading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [search, setSearch] = React.useState();
  const [menuList, setMenuList] = React.useState(MockData);
  const windowHeight = window.innerHeight;
  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setCurrentPage(pagination.current);
    getMenus({
      search,
      page: pagination.current,
      field,
      position: order == "descend" ? "DESC" : "ASC",
    });
  };
  return (
    <div>
      <Header isSearch={false} />
      <div className="container-box body_history">
        <BatchMenuBar title="Batch Settlements / Batch History" />
        <div style={{ flex: 1 }}>
          <Table
            loading={isLoading}
            rowKey="order_number"
            columns={columns}
            dataSource={menuList}
            className="table-menu"
            rowClassName={"row-table-menu"}
            onRow={(record, index) => ({
              onClick: () => {
                // history.push(`edit_menu/${record?.entity_id}`);
                console.log(record);
              },
            })}
            scroll={{ y: windowHeight - 300 }}
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: totalPage * 10,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
}

const MockData = [
  {
    id: "87341",
    created_at: "09/12/2024 12:31 AM",
    invoice_number: "22632323",
    status: "Paid",
    payment: {
      method: "Visa",
      last4: "1234",
    },
    type: "CREDIT",
    total: "10.00",
  },
];
