import React, { createContext, useState, useEffect } from "react";

// Tạo context
const ThemeContext = createContext();
export const themeMode = {
  lightTheme: {
    semanticPositive: "#198754",
    semanticWarning: "#ffc107",
    semanticCritcal: "#dc3545",
    semanticInfo: "#4285f4",
    semanticInfoLight: "#0dcaf0",
    fieldBackground: "#eaecf0",
    formBackground: "#ffffff",
    fieldTextIcon: "#333741",
    itemCardBackground: "#f9f9f9",
    itemCardText: "#1e1e1e",
    tabBGContainer: "#f9f9f9",
    pRIMARY1: "#f0fee7",
    pRIMARY2: "#defccb",
    pRIMARY3: "#bef99d",
    pRIMARY4: "#95f264",
    pRIMARY5: "#4fcc16",
    pRIMARY6Primary: "#389e0d",
    pRIMARY7: "#2d7c0f",
    pRIMARY8: "#286212",
    pRIMARY9: "#245314",
    pRIMARY10: "#0e2e05",
    tEXTPrimary: "#1d2433",
    tEXTSecondary: "#4a505c",
    tEXTDisabled: "#6c707a",
    nEUTRALPrimary: "#ffffff",
    nEUTRALBase: "#f8f9fc",
    nEUTRALSecBG: "#f1f3f9",
    nEUTRALLine: "#e1e6ef",
    tERTIARY1BG: "#f8f5ff",
    tERTIARY2Default: "#8a54f7",
    tERTIARY3Hover: "#6d35de",
    tERTIARY4Pressed: "#5221b5",
    sUCCESS1BG: "#edfdf8",
    sUCCESS2Default: "#08875d",
    sUCCESS3Hover: "#03724d",
    sUCCESS4Pressed: "#066042",
    wARNING1BG: "#fff8eb",
    wARNING2Default: "#b25e09",
    wARNING3Hover: "#96530f",
    wARNING4Pressed: "#7f460d",
    eRROR1BG: "#fef1f2",
    eRROR2Default: "#e02d3c",
    eRROR3Hover: "#ba2532",
    eRROR4Pressed: "#981b25",
    sECONDARY1Light: "#fffcea",
    sECONDARY2Default: "#ff9d00",
    sECONDARY3Hover: "#e27500",
    sECONDARY4Pressed: "#983d08",
    iNFO1BG: "#edf8ff",
    iNFO2Default: "#1677ff",
    iNFO3Hover: "#0f4abe",
    iNFO4Pressed: "#112a5a",
  },
  darkTheme: {
    semanticPositive: "#146c43",
    semanticWarning: "#cc9a05",
    semanticCritcal: "#b02a37",
    semanticInfo: "#346ac3",
    semanticInfoLight: "#0aa1c0",
    fieldBackground: "#161b26",
    formBackground: "#1f242f",
    fieldTextIcon: "#f5f5f6",
    itemCardBackground: "#0c111d",
    itemCardText: "#fefefe",
    tabBGContainer: "#182230",
    pRIMARY1: "#1c2e21",
    pRIMARY2: "#224120",
    pRIMARY3: "#2b5322",
    pRIMARY4: "#326d1f",
    pRIMARY5: "#3d8f1c",
    pRIMARY6Primary: "#47b219",
    pRIMARY7: "#69c639",
    pRIMARY8: "#8fdc60",
    pRIMARY9: "#b3ec8c",
    pRIMARY10: "#d8fbbc",
    tEXTPrimary: "#ffffff",
    tEXTSecondary: "#c2c2c4",
    tEXTDisabled: "#9d9ea1",
    nEUTRALPrimary: "#1b1f27",
    nEUTRALBase: "#0a0d14",
    nEUTRALSecBG: "#23272f",
    nEUTRALLine: "#3f444d",
    tERTIARY1BG: "#03010a",
    tERTIARY2Default: "#4110a2",
    tERTIARY3Hover: "#5c29c2",
    tERTIARY4Pressed: "#7e51d6",
    sUCCESS1BG: "#03120d",
    sUCCESS2Default: "#7ef1cb",
    sUCCESS3Hover: "#92f6d5",
    sUCCESS4Pressed: "#a4f4d9",
    wARNING1BG: "#130d01",
    wARNING2Default: "#eda154",
    wARNING3Hover: "#eaad71",
    wARNING4Pressed: "#ecba88",
    eRROR1BG: "#0e0203",
    eRROR2Default: "#c82835",
    eRROR3Hover: "#d24b57",
    eRROR4Pressed: "#de6e77",
    sECONDARY1Light: "#131101",
    sECONDARY2Default: "#f29a0d",
    sECONDARY3Hover: "#f4922a",
    sECONDARY4Pressed: "#f09f70",
    iNFO1BG: "#010c13",
    iNFO2Default: "#0c64df",
    iNFO3Hover: "#4a7fe8",
    iNFO4Pressed: "#a9c0ea",
  },
};
const ThemeProvider = ({ children }) => {
  const [isLightMode, setIsLightMode] = useState(true);
  const [theme, setTheme] = useState(themeMode.lightTheme);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === themeMode.lightTheme
        ? themeMode.darkTheme
        : themeMode.lightTheme
    );
  };

  const toggleMode = () => {
    localStorage.setItem("theme", isLightMode ? "dark" : "light");
    setIsLightMode((prevMode) => !prevMode);
  };
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      setIsLightMode(true);
      setTheme(themeMode.lightTheme);
      console.log("light mode");
    } else {
      console.log("dark mode");
    }
  }, []);
  return (
    <ThemeContext.Provider
      value={{ isLightMode, toggleMode, theme, toggleTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
