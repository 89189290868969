import { useEffect, useState } from "react";
import { Modal, Spin, notification } from "antd";
import { Button } from "antd";
import ButtonSubmit from "./buttonSubmit";
import { apiGetPrinterDeviceList, apiSetPrinterDevice } from "apis/Printer";
import RadioBtnSelected from "./icons/radioBtnSelected";

const ModalSetUpPrinter = ({ onPressOK, isVisibleModal, setVisibleMoal }) => {
  const [selectedOption, setSelectedOption] = useState();
  const [posDeviceList, setPosDeviceList] = useState([]);
  useEffect(() => {
    apiGetPrinterDeviceList().then((res) => {
      setPosDeviceList(res?.data?.merchantGetListDevice?.prints);
    });
  }, []);
  const handleOk = () => {
    if (selectedOption) {
      apiSetPrinterDevice({ printer_id: selectedOption?.id }).then((res) => {
        Modal.success({
          title: "Success",
          content: "Printer device set successfully",
          centered: true,
        });
      });
    }
    setVisibleMoal(false);
  };

  const handleCancel = () => {
    setVisibleMoal(false);
  };

  const handleChange = (item) => {
    setSelectedOption(item);
  };

  return (
    <>
      <Modal
        open={isVisibleModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ borderRadius: 16 }}
        styles={{
          content: {
            backgroundColor: "var(--neutral-primary)",
            boxShadow: "none",
          }, // turns the Modal #191919,
          header: {
            background: "var(--neutral-primary)",
            color: "white",
          },
        }}
        closeIcon={null}
        footer={null}
      >
        <div style={{ paddingTop: 8 }}>
          <p
            style={{
              color: "var(--text-primary)",
              fontSize: 24,
              fontWeight: "600",
              marginBottom: 24,
            }}
          >
            Printer Device List
          </p>
          {posDeviceList?.length > 0 ? (
            posDeviceList?.map?.((pos) => (
              <Button
                key={`pos ${pos?.id}`}
                style={{
                  height: 56,
                  width: "100%",
                  background: "var(--neutral-base)",

                  borderRadius: 8,
                  border: `1px solid  var(--neutral-line)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: 12,
                }}
                onClick={() => handleChange(pos)}
              >
                <div
                  style={{
                    width: 30,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {pos?.id == selectedOption?.id && <RadioBtnSelected />}
                </div>
                <p>{pos?.printer_name}</p>
              </Button>
            ))
          ) : (
            <Spin />
          )}

          <ButtonSubmit title="Select" onClick={handleOk} />
        </div>
      </Modal>
    </>
  );
};

export default ModalSetUpPrinter;
