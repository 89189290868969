import React, { useRef, useEffect, useState } from "react";
import Header from "components/Header";
import {
  apiGetDetailOrder,
  apiGetListOrder,
  apiGetOrderHistory,
  apiGetOrderStatusHistory,
} from "apis/Order";
import moment from "moment";
import "./index.scss";
import arrowBack from "../../assets/arrow.svg";
import historyIcon from "../../assets/history_icon.svg";
import { customNotification, formatPrice } from "utils/CommomFunction";
// import { firebaseMessaging } from "firebaseInit";
import { useHistory } from "react-router-dom";
import sound from "../../assets/notification.mp3";
import Notification from "components/Notification";
import { apiGetNotificationList } from "apis/Notification";
import { Table, Modal, Tag, DatePicker } from "antd";
import { CloseCircleOutlined, DollarTwoTone } from "@ant-design/icons";
import { renderFooterRefunds } from "./render";
import { renderModalData } from "pages/Home/render";
import { apiGetShipperList } from "apis/Shipper";
import { columnRevenue } from "./columns";
import { mockRevenueData } from "./mockdata";
import { CustomInput } from "components";
import { useTranslation } from "react-i18next";
import ButtonAction from "components/Button/ButtonAction";

const { confirm } = Modal;

const OrderHistory = () => {
  const [orderList, setOrderList] = useState([]);
  const [orderListFilter, setOrderListFilter] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalRevenue, setIsShowModalRevenue] = useState(false);
  const [isShowModalOrderStatus, setIsShowModalOrderStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [dataOrderModal, setDataOrderModal] = useState();
  const [shipperList, setShipperList] = useState();
  const [orderId, setOrderId] = useState();
  const [revenueDataList, setRevenueDataList] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const history = useHistory();
  const [reload, set_reload] = useState(false);
  const loopPlayAudio = useRef();
  const playAudio = useRef();
  const { t } = useTranslation();
  var blurred = false;

  const windowHeight = window.innerHeight;

  const getOrderStatusList = (orderId) => {
    apiGetOrderStatusHistory({ orderId }).then((res) => {
      if (res.data && !res.errors) {
        setOrderStatusList(res.data.getOrderStatusHistory.list.reverse());
      }
    });
  };

  const renderStatus = (record) => {
    let color = "#F0810D";
    let content = t("canceled");
    let padding = "5px 0px";
    switch (record.status) {
      case "complete":
        color = "var(--primary-6)";
        content = t("completed");
        padding = "5px 0px";
        break;
    }
    return (
      <Tag
        color={color}
        style={{
          padding: padding,
          width: 100,
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {content}
      </Tag>
    );
  };

  const columns = [
    {
      title: t("order_number"),
      dataIndex: "order_number",
      width: 100,
      render: (order_number) => <div>#{order_number}</div>,
    },
    {
      title: t("createAt"),
      dataIndex: "created_at",
      align: "center",
      width: 150,
      render: (time) => (
        <div className="order-price">{moment(time).format("YYYY-MM-DD ")}</div>
      ),
    },
    {
      title: t("shippingMethod"),
      dataIndex: "shipping_method",
      align: "center",
      width: 200,
      render: (method) => (
        <div className="order-price" style={{ textAlign: "center" }}>
          {method === "freeshipping_freeshipping"
            ? "Free Shipping"
            : method === "mageworxpickup_mageworxpickup"
            ? "Pickup"
            : method}
        </div>
      ),
    },
    // {
    //   title: "SHIPPER",
    //   dataIndex: "shipper_info",
    //   align: "left",
    //   width: 150,
    //   render: (shipper_info) => {
    //     let shipper = shipperList?.find(
    //       (shipper) => shipper.id === shipper_info[0]?.id
    //     );
    //     if (shipper) {
    //       return <div>{`${shipper?.last_name} ${shipper?.first_name}`}</div>;
    //     }
    //   },
    // },
    {
      title: t("grandTotal"),
      dataIndex: "grand_total",
      align: "center",
      width: 150,
      render: (grand_total) => (
        <div className="order-price">$ {grand_total.toFixed(2)}</div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (status, record) => renderStatus(record),
    },
    {
      title: "",
      dataIndex: "action",
      align: "right",
      width: 100,
      render: (status) => (
        <div key={1} className="view-more">
          {t("view")}
        </div>
      ),
    },
  ];
  const setReload = () => {
    apiGetListOrder().then((res) => {
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrders.orders);
      }
    });
  };
  useEffect(() => {
    // firebaseMessaging.onMessage((payload) => {
    //   set_reload(!reload);
    //   setReload();
    //   apiGetNotificationList({ type: "merchant" }).then((res) => {
    //     if (
    //       res.data &&
    //       !res.errors &&
    //       res.data.getMerchantNotificationList.list.length &&
    //       res.data.getMerchantNotificationList.list[0].is_read === 0
    //     ) {
    //       customNotification(
    //         "success",
    //         <Notification
    //           title={res.data.getMerchantNotificationList.list[0].title}
    //           content={res.data.getMerchantNotificationList.list[0].content}
    //         />,
    //         "notification-custom"
    //       );
    //     }
    //   });
    // });
    setIsLoading(true);

    // apiGetShipperList().then((res) => {
    //   if (!res.errors && res.data) {
    //     setShipperList(res.data.getShipperList.list);
    //   }
    // });

    apiGetOrderHistory().then((res) => {
      console.log("response order history : ", res);
      setIsLoading(false);
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrderHistory?.orders);
        setOrderListFilter(res.data.merchantOrderHistory?.orders);
      }
    });

    // return () => {
    //   clearInterval(loopPlayAudio.current);
    //   clearTimeout(playAudio.current);
    // };
  }, []);
  const fetchOrderDetail = (id) => {
    apiGetDetailOrder({ id }).then((res) => {
      if (!res.errors && res.data) {
        setOrderDetail(res.data.orderDetail);
        setDataOrderModal(res.data.orderDetail);
      }
    });
  };

  const renderTitleModal = () => {
    return (
      <>
        <div className="title-left">
          <h3>
            {t("order")} #{orderDetail?.order_number}{" "}
            <Tag
              color={
                orderDetail?.status === "complete"
                  ? "var(--primary-6)"
                  : "#9E9E9E"
              }
              style={{ paddingTop: "0.5px" }}
            >
              {orderDetail?.status === "complete"
                ? t("completed")
                : t("canceled")}
            </Tag>
          </h3>
          {dataOrderModal?.external_order_id && (
            <p style={{ fontWeight: "400", fontSize: 13 }}>
              {`Mã đơn ${
                dataOrderModal?.order_type == "CALL CENTER" ||
                dataOrderModal?.order_type == "STORE"
                  ? "Hoa Sao"
                  : "zalo"
              }: `}
              #
              {dataOrderModal?.order_type == "CALL CENTER" ||
              dataOrderModal?.order_type == "STORE"
                ? dataOrderModal?.external_order_id
                : dataOrderModal?.external_order_id
                    ?.toString()
                    ?.slice(
                      dataOrderModal?.external_order_id?.toString().length - 9
                    )}{" "}
            </p>
          )}
          <h5>
            <p>{`${t("receivedAt")} ${moment(orderDetail?.created_at).format(
              "hh:mm A"
            )}`}</p>
            {orderDetail?.assign_from?.name &&
              orderDetail?.status === "pending" && (
                <>
                  <span></span>
                  <b>{`${t("movedFrom")} ${orderDetail?.assign_from?.name}`}</b>
                </>
              )}
          </h5>
        </div>

        <ButtonAction
          onClick={() => history.push(`/bill?orderId=${orderDetail.id}`)}
          title={t("viewBill")}
          color={"#00B77F"}
        />
      </>
    );
  };
  const handleOkRefund = (id) => {};

  const handleSubmitRefund = (orderDetail) => {
    return confirm({
      className: "refund-confirm-modal",
      icon: <></>,
      content: (
        <div className="confirm-refund">
          {t("confirmRefundMoney")} <br />{" "}
          <span>#{orderDetail?.order_number}</span>
        </div>
      ),
      footer: <></>,
      onOk: () => handleOkRefund(orderDetail.id),
      okText: t("confirmText"),
      cancelText: (
        <>
          <CloseCircleOutlined
            style={{ color: "#e31837", fontSize: "16px" }}
            size={4}
          />{" "}
          {t("cancelText")}
        </>
      ),
    });
  };

  const handleSearch = (e) => {
    let newOrderListFilter = [...orderList];
    newOrderListFilter = newOrderListFilter.filter((order) =>
      order.order_number.includes(e.target.value)
    );
    setOrderId(e.target.value);
    setOrderListFilter(newOrderListFilter);
  };
  return (
    <>
      <Header reload={reload} />
      <div className="container-box body_history">
        <div className="header-bottom">
          <h3 className="header-bottom-left">
            <img src={historyIcon} alt="icon" />
            {t("orderHistory")}
          </h3>
          <div className="header-bottom-right">
            {/* <button className="back-btn" onClick={() => setIsShowModalRevenue(true)} style={{ backgroundColor: '#00CC00' }}>
              {" "}
              <DollarTwoTone twoToneColor="#EB5151" style={{ fontSize: '18px', marginRight: '5px', color: '#FCE538' }} />
              DOANH THU
            </button> */}
            <div className="search-bar">
              <CustomInput
                placeholder={t("search")}
                value={orderId}
                onChange={handleSearch}
              />
            </div>

            <button className="back-btn" onClick={() => history.push("/home")}>
              {" "}
              <img src={arrowBack} style={{ marginRight: "10px" }} />
              {t("goback")}
            </button>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <Table
            rowKey="order_number"
            columns={columns}
            loading={isLoading}
            onRow={(record, index) => ({
              onClick: () => {
                fetchOrderDetail(record.id);
                setDataOrderModal(record);
                setIsShowModal(true);
              },
            })}
            dataSource={orderListFilter}
            className="table-refund"
            pagination={true}
            scroll={{ y: windowHeight - 300 }}
          />
        </div>
      </div>
      <Modal
        centered={true}
        destroyOnClose={true}
        className="modal-order-history"
        open={isShowModal}
        onCancel={() => setIsShowModal(false)}
        title={renderTitleModal()}
        width={900}
        footer={renderFooterRefunds(orderDetail, isLoading, handleSubmitRefund)}
      >
        {renderModalData(orderDetail, dataOrderModal)}
      </Modal>
      <Modal
        centered={true}
        destroyOnClose={true}
        className="modal-revenue"
        open={isShowModalRevenue}
        onCancel={() => setIsShowModalRevenue(false)}
        title={<h3>{t("revenue")}</h3>}
        footer={<></>}
        width={995}
      >
        <div
          style={{
            marginBottom: "20px",
            textAlign: "right",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{t("date")}:</span>{" "}
          <DatePicker defaultValue={moment()} format="YYYY-MM-DD " />
        </div>

        <Table
          rowKey="order_number"
          columns={columnRevenue}
          dataSource={mockRevenueData}
          className="table-refund"
          pagination={false}
        />
      </Modal>
      <Modal
        centered={true}
        destroyOnClose={true}
        className="modal-order-status"
        open={isShowModalOrderStatus}
        onCancel={() => setIsShowModalOrderStatus(false)}
        title={<h3>Note for this Order</h3>}
        footer={<></>}
        width={580}
      >
        <div
          className="contentOrderHistory"
          style={{ overflowY: "scroll", height: 420 }}
        >
          {orderStatusList &&
            orderStatusList.map((status, idx) => (
              <div className="order-status" key={idx}>
                <h3 className="order-status-title">
                  {status?.comment[0]?.toUpperCase() +
                    status?.comment?.slice(1)}
                </h3>
                <span className="order-status-timeline">
                  Comment added{" "}
                  {moment(status?.created_at).format("MMMM D, YYYY hh:mm:ss A")}
                </span>
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default OrderHistory;
