import React, { useContext } from "react";
import logoImage from "../assets/logo/logo.png";
import menuIcon from "../assets/headerContainer/menu.png";
import bellIcon from "../assets/headerContainer/bell.png";
import questionIcon from "../assets/headerContainer/question.png";
import { ThemeContext } from "handlers/ThemeContext";
import Notify from "../components/Notify";
import { getLocalStorage } from "utils/CommomFunction";
import DrawerMenu from "./DrawerMenu";
export default function Container({ children }) {
  const { isLightMode, toggleMode } = useContext(ThemeContext);
  const isLogin = () => {
    return getLocalStorage("access_token");
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          paddingInline: 20,
          paddingTop: 20,
          background: isLightMode ? "#F3F3F3" : "#0B111D ",
          justifyContent: "space-between",
        }}
      >
        <img
          src={logoImage}
          style={{
            height: 44,
            width: "auto",
            resizeMode: "contain",
          }}
          alt="React Starter"
        />
        <div style={{ display: "flex", gap: 20 }}>
          <img
            src={menuIcon}
            style={{
              height: 36,
              width: 36,
              resizeMode: "contain",
              cursor: "pointer",
            }}
            onClick={toggleMode}
          />
          {isLogin() && <DrawerMenu />}
          {isLogin() && <Notify />}
          <img
            src={questionIcon}
            style={{
              height: 36,
              width: 36,
              resizeMode: "contain",
              cursor: "pointer",
            }}
          />
        </div>
      </div>

      {children}
    </div>
  );
}
