import React from "react";
import Header from "components/Header";
import MenuBar from "components/MenuBar";
import SearchInput from "components/SearchInput";
import CustomButton from "components/CustomButton";
import icon_plus from "assets/icon/icon_plus.svg";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { apiGetCategory } from "apis/Category";
import { debounce } from "lodash";
import { AlertContext } from "handlers/alertContext";
import { useContext } from "react";
import "./index.scss";
import { columnsCategory } from "./columns";

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [categoryList, setCategoryList] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [search, setSearch] = React.useState();
  const { openModal } = useContext(AlertContext);

  const windowHeight = window.innerHeight;

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setCurrentPage(pagination.current);
    getCategory({
      search,
      page: pagination.current,
      field: getFieldFormatedBySort(field),
      position: order == "descend" ? "DESC" : "ASC",
    });
  };

  const getCategory = ({ search, page, field, position }) => {
    setLoading(true);
    apiGetCategory({
      search,
      currentPage: page,
      field: field ?? "id",
      position: position ?? "ASC",
    }).then((res) => {
      if (res?.errors) {
        openModal(res?.errors?.[0]?.message);
        return;
      }
      setCategoryList(res?.data?.merchantCategories?.items ?? []);
      setTotalPage(res?.data?.merchantCategories?.page_info?.total_pages);
      setLoading(false);
    });
  };
  const searchData = () => {
    getCategory({ search, page: 1 });
    setCurrentPage(1);
  };
  const delayedSearchData = debounce(() => searchData(), 350);

  React.useEffect(() => {
    delayedSearchData();
  }, [search]);

  return (
    <>
      <Header />
      <div className="container-box body_history">
        <MenuBar title="Menu Management / Categories" />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            placeholder="Category name ..."
          />
          <CustomButton
            style={{ marginLeft: 16 }}
            leftIcon={icon_plus}
            title="New category"
            onClick={() => history.push("new_category")}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Table
            loading={isLoading}
            rowKey="order_number"
            columns={columnsCategory}
            dataSource={categoryList}
            className="table-menu"
            onRow={(record, index) => ({
              onClick: () => {
                history.push(`edit_category/${record?.id}`);
              },
            })}
            scroll={{ y: windowHeight - 300 }}
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: totalPage * 10,
              showSizeChanger: true,
            }}
            ß
            onChange={handleTableChange}
          />
        </div>
      </div>
    </>
  );
};

export default Index;

const getFieldFormatedBySort = (field) => {
  if (field === "id") return "entity_id";
  return field;
};
