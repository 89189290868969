import { callApi } from "..";
export const apiGetPrinterDeviceList = () =>
  callApi(
    `{
  merchantGetListDevice {
    restaurant_id,
    prints {
      id
      printer_name
    }
  }
}`
  );

export const apiSetPrinterDevice = (data) =>
  callApi(
    `mutation($printer_id:Int!) {
    merchantConfigPrinter (printer_id: $printer_id)
}`,
    data
  );

export const apiPrintInvoice = (data) =>
  callApi(
    `mutation ($invoice_number: String!) {
    merchantPrintReceipt (invoice_number: $invoice_number)
}`,
    data
  );
