import React, { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { getLocalStorage } from "./utils/CommomFunction/index";
import { HomePage, PageLogin } from "./pages";
import NotFound from "./pages/404/NotFound";
import Maintenance from "./pages/404/Maintenance";
import RefundPage from "pages/Refund";
import ShipperPage from "pages/Shipper";
import OrderHistory from "pages/OrderHistory";
import BillPage from "pages/BillDetail";
import MenuPage from "pages/Menu";
import NewMenuPage from "pages/MenuNew";
import CategoryPage from "pages/Category";
import NewCategoryPage from "pages/CategoryNew";
import MenuItems from "pages/MenuItems";
import NewMenuItems from "pages/ItemNew";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "handlers/ThemeContext";
import BatchSettlements from "pages/BatchSettlements/Settle";
import Transactions from "pages/BatchSettlements/Transactions";
import BatchHistory from "pages/BatchSettlements/BatchHistory";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const MyRoutes = () => {
  const pathname = useLocation().pathname || "";
  const query = useQuery();
  const token = query.get("token");
  const { isLightMode } = useContext(ThemeContext);
  const navigation = useHistory();
  useEffect(() => {
    if (token) {
      localStorage.setItem("access_token", token);
      // navigation.push(pathname);
    }
  }, [token]);
  const isLogin = () => {
    return getLocalStorage("access_token");
  };
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocalStorage("i18nextLng") || "en");
  }, []);
  return (
    <div data-theme={isLightMode ? "light" : "dark"}>
      <Switch>
        <Route exact path="/login">
          {isLogin() ? <Redirect to="/home" /> : <PageLogin />}
        </Route>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/refunds">
          {isLogin() ? <RefundPage /> : <PageLogin />}
        </Route>
        <Route exact path="/history">
          {isLogin() ? <OrderHistory /> : <PageLogin />}
        </Route>
        <Route exact path="/shipper">
          {isLogin() ? <ShipperPage /> : <PageLogin />}
        </Route>
        {/*<Route exact path="/shipper">*/}
        {/*  {isLogin() ? <ShipperPage /> : <PageLogin />}*/}
        {/*</Route>*/}
        {/*<Route exact path="/order-history">*/}
        {/*  {isLogin() ? <OrderHistory /> : <PageLogin />}*/}
        {/*</Route>*/}
        <Route exact path="/bill">
          {isLogin() ? <BillPage /> : <PageLogin />}
        </Route>
        <Route exact path="/menu">
          {isLogin() ? <MenuPage /> : <PageLogin />}
        </Route>
        <Route exact path="/new_menu">
          {isLogin() ? <NewMenuPage /> : <PageLogin />}
        </Route>
        <Route exact path="/edit_menu/:id">
          {isLogin() ? <NewMenuPage /> : <PageLogin />}
        </Route>
        <Route exact path="/category">
          {isLogin() ? <CategoryPage /> : <PageLogin />}
        </Route>
        <Route exact path="/new_category">
          {isLogin() ? <NewCategoryPage /> : <PageLogin />}
        </Route>
        <Route exact path="/edit_category/:id">
          {isLogin() ? <NewCategoryPage /> : <PageLogin />}
        </Route>
        <Route exact path="/items">
          {isLogin() ? <MenuItems /> : <PageLogin />}
        </Route>
        <Route exact path="/settle">
          {isLogin() ? <BatchSettlements /> : <PageLogin />}
        </Route>
        <Route exact path="/transactions">
          {isLogin() ? <Transactions /> : <PageLogin />}
        </Route>
        <Route exact path="/batchHistory">
          {isLogin() ? <BatchHistory /> : <PageLogin />}
        </Route>
        <Route exact path="/new_item">
          {isLogin() ? <NewMenuItems /> : <PageLogin />}
        </Route>
        <Route exact path="/edit_item/:id">
          {isLogin() ? <NewMenuItems /> : <PageLogin />}
        </Route>
        <Route exact path="/">
          {isLogin() ? <Redirect to="/home" /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/notfound" component={NotFound}></Route>
        <Route exact path="/maintenance" component={Maintenance}></Route>
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
};
